import { Component } from '@angular/core';

@Component({
  selector: 'app-sponsoring-entities',
  templateUrl: './sponsoring-entities.component.html',
  styleUrls: ['./sponsoring-entities.component.scss']
})
export class SponsoringEntitiesComponent {

}
