<section class="container-fluid" style="background-color: #070813 !important;" *ngIf="winner">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 my-5">
                <h1 class="fw-bold text-white text-center">Últimos ganhadores</h1>
            </div>
            <div class="col-lg-8">
                <iframe *ngIf="winner?.campaign?.linkWinners" id="videoFrame" [width]="videoWidth"
                    [height]="videoHeight" [src]="winner?.campaign?.linkWinners" frameborder="0"
                    allowfullscreen></iframe>
                <p class="fw-bold mt-4 text-white">{{winner?.createdAt | date: 'dd/MM/yyyy'}} -
                    {{winner?.campaign?.stages[0]?.description}}</p>
                <p class="fw-bold text-white">Nome: <span class="fw-light">{{maskedFullName}}</span></p>
                <p class="fw-bold text-white">Telefone: <span class="fw-light">{{maskPhone}}</span></p>
                <p class="fw-bold text-white">Cidade/UF: <span
                        class="fw-light">{{winner?.sale?.buyerData?.address?.city}} -
                        {{winner?.sale?.buyerData?.address?.state}}</span></p>
            </div>
            <div class="col-12 pt-5">
                <hr color="#818181">
            </div>
        </div>
    </div>
</section>