<div class="navbar-area navbar-area-with-position-relative" style="background-color: #070813 !important;">
  <div class="karlson-nav ">
    <div class="container-fluid ">
      <nav class="navbar navbar-expand-lg navbar-light">
        <a class="navbar-brand" routerLink="/"><img src="../../../../assets/img/logo-white.svg" alt="logo"
            class="logo-custom-nav"></a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <i class="bi bi-list" style="color: #00BE04 !important;"></i>
        </button>
        <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent"
          style="background-color: #070813 !important; border: none !important;">
          <ul class="navbar-nav text-white">
            <li class="nav-item text-white d-flex align-items-center justify-content-center">
              <a routerLinkActive="active" class="nav-link text-white cursor-pointer"
                (click)="openPdf('pdf1')">Regulamento</a>
            </li>
            <li class="nav-item text-white d-flex align-items-center justify-content-center">
              <a routerLinkActive="active" class="nav-link text-white cursor-pointer"
                (click)="openPdf('pdf2')">Condições
                gerais</a>
            </li>
            <li class="nav-item text-white d-flex align-items-center justify-content-center">
              <a routerLink="/results" routerLinkActive="active" class="nav-link text-white">Conferir resultados</a>
            </li>
            <li class="nav-item text-white d-flex align-items-center justify-content-center">
              <a routerLink="/my-titles" routerLinkActive="active" class="nav-link text-white">Consultar compras</a>
            </li>
            <li class="nav-item text-white d-flex align-items-center justify-content-center">
              <a routerLink="/faq" routerLinkActive="active" class="nav-link text-white">FAQ</a>
            </li>
            <!--            <li class="nav-item d-flex align-items-center justify-content-center mt-4 mt-lg-0">
              <a href="/checkout">
                <button type="button" class="btn text-white px-4 py-2 rounded-pill navbar-button-custom fs-14">Compre
                  Agora</button>
              </a>
            </li> -->
            <li class="d-flex align-items-center justify-content-center">
              <a class="nav-link text-dark"
                href="https://www.instagram.com/mundodasorteoficial/?igshid=MzRlODBiNWFlZA%3D%3D" target="_blank">
                <img src="assets/instagram.png" style="max-height: 40px;"></a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</div>
<!-- End Navbar Area -->

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{ modalTitle }}</h4>
    <div>
      <span (click)="modal.dismiss()" class="fs-3 font-weight-700 cursor-pointer">X</span>
    </div>
  </div>
  <div class="modal-body">
    <iframe [src]="pdfUrl" width="100%" height="600px"></iframe>
  </div>
</ng-template>

<ng-template #modalQuemSomos let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Quem Somos?</h4>
    <div>
      <span (click)="modal.dismiss()" class="fs-3 font-weight-700 cursor-pointer">X</span>
    </div>
  </div>
  <div class="modal-body d-flex justify-content-center align-items-center flex-column">
    <div>
      <img class="img-fluid" src="../../../assets/icons/quem-somos-logo.png" width="320px" alt="quem somos logo">
    </div>
    <div>
      <p class="text-center font-weight-700">O MUNDO DA SORTE V.01 é um Título de Capitalização da Modalidade
        Filantropia Premiável, de
        pagamento
        único, emitido
        pela VIA CAPITALIZAÇÃO
        S.A. (VIACAP), Sociedade de Capitalização inscrita no CNPJ sob o Número 88.076.302/0001-94, com sede na Av.
        Carlos Gomes, 222, Conj.
        901,
        Bairro Boa Vista, Porto Alegre/RS, CEP 90480-000</p>
    </div>
  </div>
</ng-template>