<div class="container-participe">
    <div class="row align-items-center justify-content-center py-4 m-0">
        <div class="col-lg-4 d-flex justify-content-center align-items-center mb-4 mb-lg-0">
            <div>
                <div class="text-center text-white mb-2">
                    Entidade beneficiada
                </div>
                <div class="d-flex justify-content-center mb-3">
                    <img class="img-fluid" src="../../../../../assets/icons/ABESS-dark.svg"
                        alt="Entidade beneficiada ABESS">
                </div>
            </div>
        </div>

        <div class="col-lg-4 d-flex justify-content-center align-items-center mb-4 mb-lg-0">
            <div>
                <div class="text-center text-white mb-2">
                    Título emitido por
                </div>
                <div class="d-flex justify-content-center mb-3">
                    <img class="img-fluid" src="../../../../../assets/icons/viacap-dark.svg"
                        alt="Título emitido por ViaCap">
                </div>
            </div>
        </div>

        <div class="col-lg-4 d-flex justify-content-center align-items-center mb-4 mb-lg-0">
            <div>
                <div class="text-center text-white mb-2">
                    Distribuído por
                </div>
                <div class="d-flex justify-content-center mb-3">
                    <img class="img-fluid" src="../../../../../assets/icons/logo-black.svg"
                        alt="Distribuído por Mundo da Sorte">
                </div>
            </div>
        </div>
    </div>
</div>