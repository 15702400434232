<div class="d-flex justify-content-center align-items-center flex-column container-checkout">

  <div *ngIf="stepOne" class="container card-checkout p-0 rounded mt-0 mt-md-5">
    <div class="py-4 px-2 d-flex justify-content-between align-items-center">
      <a href="/">
        <i class="bi bi-chevron-left cursor-pointer text-white"></i>
      </a>
      <div>
        <img class="img-fluid" src="../../../../assets/img/logo-white.svg" alt="logo Mundo da Sorte">
      </div>
      <div style="height: 26px; width: 26px;"></div>
    </div>
    <div>

      <div *ngIf="stageSelect?.prizes?.length > 0">
        <div *ngIf="!promotionEnded" class="d-flex justify-content-between bg-warning px-3 py-3 mx-2 rounded mb-5 mt-3">
          <div>
            <p class="font-weight-700">Tempo Limitado</p>
            <p class="fs-14 font-weight-600">Preço promocional encerra em</p>
          </div>
          <app-timer [stageSelect]="stageSelect"></app-timer>
        </div>

        <div *ngIf="promotionEnded" class="d-flex justify-content-center bg-warning px-2 py-4 mx-2 rounded mb-5">
          <p class="font-weight-700 pb-1">A promoção acabou!</p>
        </div>
      </div>

      <div *ngIf="promotionEnded" class="container">
        <p class="text-white fw-bold text-center mb-4">Confira nossas outras promoções</p>
        <div *ngFor="let item of outhersCampaign" class="">

          <div *ngIf="item?.prizes?.length > 0">
            <div *ngIf="!promotionEnded"
              class="d-flex justify-content-between bg-warning px-3 py-3 mx-2 rounded mb-5 mt-3">
              <div>
                <p class="font-weight-700">Tempo Limitado</p>
                <p class="fs-14 font-weight-600">Preço promocional encerra em</p>
              </div>
              <app-timer [stageSelect]="item"></app-timer>
            </div>

            <div *ngIf="promotionEnded" class="d-flex justify-content-center bg-warning px-2 py-4 mx-2 rounded mb-5">
              <p class="font-weight-700 pb-1">A promoção acabou!</p>
            </div>
          </div>

          <p class="premios-title">{{item?.description || '-'}}</p>
          <div class="row justify-content-center px-3">
            <div *ngFor="let content of outhersCampaign?.prizes" class="col-md-4 mt-3">
              <div class="card p-4">
                <h4 class="fw-bold text-center">{{content.title}}</h4>
                <h3 class="fw-bold text-center text-primary mb-0" style="max-height: 22px;">{{content.quantity}}</h3>
                <span class="fw-light text-center mb-2">Chances</span>
                <h6 class="fw-bold text-center">Por {{content.value | currency: 'R$ '}}</h6>
              </div>
            </div>
          </div>
          <div class="my-4">
            <p class="text-center premios-title">{{item?.chances?.length > 0 ? 'Quantas chances deseja?' : 'Não há sorteios no momento...'}}</p>
          </div>

          <div *ngFor="let content of item?.chances; let i = index" #elementoArray class="mt-4 unselected-card-chance"
            (click)="chanceSelect(content)"
            [ngClass]="{'pulse': content.numberOfChances === itemComMaiorQuantidade.numberOfChances}">
            <div class="d-flex flex-sm-row flex-column justify-content-between align-items-center p-4">
              <div class="d-flex">
                <img src="../../../../assets/img/logo-ticket.png" class="img-fluid">
                <div class="d-flex flex-column ms-3 justify-content-center">
                  <h2 class="fw-bold text-white">{{content.numberOfChances}} TÍTULOS</h2>
                  <p class="fw-bold text-white"><span class="buy-here-text-custom">COMPRE AQUI</span> -
                    {{content.numberOfChances}} chances de
                    ganhar</p>
                </div>
              </div>
              <div class="d-flex flex-column justify-content-center" *ngIf="content.value > content.valueWithDiscount">
                <p class="text-danger fw-bold"><span style="text-decoration: line-through;">de {{content.value
                    |
                    currency: 'R$ '}} </span>por</p>
                <h3 class="fw-bold text-white">{{content.valueWithDiscount |
                  currency: 'R$ '}}</h3>
              </div>
              <div class="d-flex flex-column justify-content-center" *ngIf="content.value <= content.valueWithDiscount">
                <h3 class="fw-bold text-white">{{content.value |
                  currency: 'R$ '}}</h3>
              </div>
            </div>
          </div>
          <p class="text-muted" style="font-size: 9px;">
            Título de Capitalização da Modalidade Filantropia Premiável de Contribuição Única emitido pela VIA
            CAPITALIZAÇÃO S.A. (VIACAP), CNPJ 88.076.302/0001-94, aprovado pelo Processo SUSEP nº
            {{item?.number}}.
            SAC 0800 740 7819. OUVIDORIA 0800 703 1989, de segunda a sexta-feira, das 8h às 17h. É proibida a venda
            de título de capitalização a menores de 16 anos. O valor não exigido dentro do prazo prescricional,
            estabelecido pela legislação em vigor, acarretará a perda desse direito. A aquisição deste título
            faculta ao adquirente a cessão de 100% do direito de resgate à A ABESS, certificada nos termos da
            legislação em vigor. Antes de contratar consulte previamente as Condições Gerais. As condições
            contratuais/regulamento deste produto protocolizadas pela sociedade junto à SUSEP poderão ser
            consultadas no endereço eletrônico www.susep.gov.br, de acordo com o número de processo constante da
            proposta. Prêmios líquidos de imposto de renda. Confira o resultado dos sorteios e as condições de
            participação em www.mundodasorteoficial.com.br. Imagens meramente ilustrativas.</p>
        </div>
        <hr>

        <div *ngIf="stageSelect?.chances?.length > 0"
          class="d-flex flex-md-row flex-column justify-content-between align-items-center mt-4 pb-4">
          <div>
            <p class="font-weight-600 text-white" *ngIf="!selectedCard?.value">Nenhum produto escolhido</p>
            <p class="text-md-start text-center font-weight-600 text-white" *ngIf="!selectedCard?.value">{{0 | currency:
              'R$ '}}
            </p>
            <p class="text-md-start text-center font-weight-600 text-white"
              *ngIf="selectedCard?.valueWithDiscount < selectedCard?.value">{{
              selectedCard?.valueWithDiscount |
              currency: 'R$ '}}
            </p>
            <p class="text-md-start text-center font-weight-600 text-white"
              *ngIf="selectedCard?.valueWithDiscount >= selectedCard?.value">{{
              selectedCard?.value |
              currency: 'R$ '}}
            </p>
          </div>

          <div class="mt-3 mt-md-0" *ngIf="selectedCard?.value">
            <button [disabled]="!selectedCard?.value" (click)="goToPayment();" type="button" [class]="selectedCard?.value
              ? 'btn text-white px-4 py-2 rounded-pill navbar-button-custom selected-card-chances-button' 
              : 'btn text-white px-4 py-2 rounded-pill navbar-button-custom-disabled selected-card-chances-button'">
              Ir para o pagamento
            </button>
          </div>
        </div>
      </div>


      <div class="container" *ngIf="!promotionEnded">
        <p class="premios-title">{{selectedCampaign?.name || '-'}}</p>
        <div class="row justify-content-center px-3">
          <div *ngFor="let item of stageSelect?.prizes" class="col-md-4 mt-3">
            <div class="card p-4">
              <h4 class="fw-bold text-center">{{item.title}}</h4>
              <h3 class="fw-bold text-center text-primary mb-0" style="max-height: 22px;">{{item.quantity}}</h3>
              <span class="fw-light text-center mb-2">Chances</span>
              <h6 class="fw-bold text-center">Por {{item.value | currency: 'R$ '}}</h6>
            </div>
          </div>
        </div>
        <div class="my-4">
          <p class="text-center premios-title">{{stageSelect?.chances?.length > 0 ? 'Quantas chances deseja?' : 'Não há sorteios no momento...'}}</p>
        </div>

        <div *ngFor="let content of stageSelect?.chances" #elementoArray class="mt-4 unselected-card-chance"
          (click)="chanceSelect(content)"
          [ngClass]="{'pulse': content.numberOfChances === itemComMaiorQuantidade.numberOfChances}">
          <div class="d-flex flex-sm-row flex-column justify-content-between align-items-center p-4">
            <div class="d-flex">
              <img src="../../../../assets/img/logo-ticket.png" class="img-fluid">
              <div class="d-flex flex-column ms-3 justify-content-center">
                <h2 class="fw-bold text-white">{{content.numberOfChances}} TÍTULOS</h2>
                <p class="fw-bold text-white"><span class="buy-here-text-custom">COMPRE AQUI</span> -
                  {{content.numberOfChances}} chances de
                  ganhar</p>
              </div>
            </div>
            <div class="d-flex flex-column justify-content-center" *ngIf="content.value > content.valueWithDiscount">
              <p class="text-danger fw-bold"><span style="text-decoration: line-through;">de {{content.value
                  |
                  currency: 'R$ '}} </span>por</p>
              <h3 class="fw-bold text-white">{{content.valueWithDiscount |
                currency: 'R$ '}}</h3>
            </div>
            <div class="d-flex flex-column justify-content-center" *ngIf="content.value <= content.valueWithDiscount">
              <h3 class="fw-bold text-white">{{content.value |
                currency: 'R$ '}}</h3>
            </div>
          </div>
        </div>
        <hr>

        <div *ngIf="stageSelect?.chances?.length > 0"
          class="d-flex flex-md-row flex-column justify-content-between align-items-center mt-4 pb-4">
          <div>
            <p class="font-weight-600 text-white" *ngIf="!selectedCard?.value">Nenhum produto escolhido</p>
            <p class="text-md-start text-center font-weight-600 text-white" *ngIf="!selectedCard?.value">{{0 | currency:
              'R$ '}}
            </p>
            <p class="text-md-start text-center font-weight-600 text-white"
              *ngIf="selectedCard?.valueWithDiscount < selectedCard?.value">{{
              selectedCard?.valueWithDiscount |
              currency: 'R$ '}}
            </p>
            <p class="text-md-start text-center font-weight-600 text-white"
              *ngIf="selectedCard?.valueWithDiscount >= selectedCard?.value">{{
              selectedCard?.value |
              currency: 'R$ '}}
            </p>
          </div>

          <div class="mt-3 mt-md-0" *ngIf="selectedCard?.value">
            <button [disabled]="!selectedCard?.value" (click)="goToPayment();" type="button" [class]="selectedCard?.value
              ? 'btn text-white px-4 py-2 rounded-pill navbar-button-custom selected-card-chances-button' 
              : 'btn text-white px-4 py-2 rounded-pill navbar-button-custom-disabled selected-card-chances-button'">
              Ir para o pagamento
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="stepTwo" class="container card-checkout p-0 rounded mt-0 mt-md-5">
    <div class="py-4 px-2 d-flex justify-content-between align-items-center">
      <div (click)="backToPayment();">
        <img class="img-fluid cursor-pointer" src="../../../assets/icons/chevron-back.svg" alt="icone de seta">
      </div>
      <div>
        <img class="img-fluid" src="../../../../assets/img/logo-white.svg" alt="logo Mundo da Sorte">
      </div>
      <div style="height: 26px; width: 26px;"></div>
    </div>

    <div class="container px-4 d-flex justify-content-between mt-4">
      <div class="d-flex flex-column align-items-center">
        <img class="img-fluid" src="../../../assets/icons/checked-icon.png" alt="icone de item verificado" width="20px">
        <p class="text-center stepper-marked-text mt-2 text-white">Escolher<br>Produto</p>
      </div>

      <div class="stepper-dots">
        ...........
      </div>

      <div class="d-flex flex-column align-items-center">
        <img class="img-fluid" src="../../../assets/icons/attention-icon.png" alt="icone de atencao" width="20px">
        <p class="text-center stepper-marked-text mt-2 text-white">Dados<br>Pessoais</p>
      </div>

      <div class="stepper-dots">
        ...........
      </div>

      <div class="d-flex flex-column align-items-center">
        <img class="img-fluid" src="../../../assets/icons/disable-icon.png" alt="icone de circulo cinza" width="20px">
        <p class="text-center stepper-disabled-text mt-2 text-white">Pagamento</p>
      </div>

      <div class="stepper-dots">
        ...........
      </div>

      <div class="d-flex flex-column align-items-center">
        <img class="img-fluid" src="../../../assets/icons/disable-icon.png" alt="icone de circulo cinza" width="20px">
        <p class="text-center stepper-disabled-text mt-2 text-white">Confirmação</p>
      </div>
    </div>

    <div class="m-3 selected-card-chance">
      <div class="d-flex flex-sm-row flex-column justify-content-between align-items-center p-4">
        <div class="d-flex">
          <img src="../../../../../assets/img/logo-ticket.png" class="img-fluid"
            style="max-width: 50px;max-height: 50px;">
          <div class="d-flex flex-column ms-3 justify-content-center">
            <h5 class="fw-bold text-white">{{selectedCard.numberOfChances}} TÍTULOS</h5>
          </div>
        </div>
        <div class="d-flex flex-column justify-content-center"
          *ngIf="selectedCard?.value > selectedCard?.valueWithDiscount">
          <p class="text-danger fw-bold"><span style="text-decoration: line-through;">de
              {{selectedCard?.value |
              currency: 'R$ '}} </span>por</p>
          <h6 class="fw-bold text-white">{{selectedCard?.valueWithDiscount |
            currency: 'R$ '}}</h6>
        </div>
        <div class="d-flex flex-column justify-content-center"
          *ngIf="selectedCard?.value <= selectedCard?.valueWithDiscount">
          <h6 class="fw-bold text-white">{{selectedCard?.value |
            currency: 'R$ '}}</h6>
        </div>
      </div>
    </div>

    <div class="mt-4 container fs-14 font-weight-500 text-white">Para continuar preencha o formulário abaixo:</div>

    <div class="mt-3 container">
      <p class="fs-6 mb-3 font-weight-500 text-white">Informações pessoais</p>
      <form class="mr-5" [formGroup]="formPersonalData">
        <div class="row">

          <div class="col-md-12 mb-3">
            <input type="text" formControlName="name" class="form-control text-white bg-transparent border rounded-3"
              id="name" placeholder="Nome Completo">

            <div *ngIf="formPersonalData.controls['name'].dirty && formPersonalData.controls['name'].errors"
              class="div-msg-error mt-2">
              <img src="../../../assets/icons/icon-alert.svg" class="img-msg-error" alt="icone de atencao">
              <p class="fs-14 text-white">Nome Completo é obrigatório.</p>
            </div>
          </div>

          <div class="col-12 col-md-6">
            <input type="text" formControlName="cpf" class="form-control text-white bg-transparent border rounded-3"
              mask="000.000.000-00" id="cpf" placeholder="CPF">
            <div
              *ngIf="formPersonalData.controls['cpf'].dirty && (formPersonalData.controls['cpf'].errors || !validarCPF(formPersonalData.controls['cpf'].value))"
              class="div-msg-error mt-2">
              <img src="../../../assets/icons/icon-alert.svg" class="img-msg-error" alt="icone de atencao">
              <p class="fs-14 text-white">CPF inválido</p>
            </div>
          </div>
          <div class="col-12 col-md-6 mt-3 mt-md-0 mb-3 mb-md-0">
            <input type="email" class="form-control text-white bg-transparent border rounded-3" id="email"
              formControlName="email" placeholder="email@email.com">
            <div *ngIf="formPersonalData.controls['email'].dirty && formPersonalData.controls['email'].errors"
              class="div-msg-error mt-2">
              <img src="../../../assets/icons/icon-alert.svg" class="img-msg-error" alt="icone de atencao">
              <p class="fs-14 text-white">Email inválido</p>
            </div>
          </div>

          <div class="col-12 col-md-6 mb-3 mt-0 mt-md-3">
            <input type="text" formControlName="phone" class="form-control text-white bg-transparent border rounded-3"
              mask="(00) 0 0000-0000" id="phone" placeholder="Celular">
            <div *ngIf="formPersonalData.controls['phone'].errors?.['minlength']" class="div-msg-error mt-2">
              <img src="../../../assets/icons/icon-alert.svg" class="img-msg-error" alt="icone de atencao">
              <p class="fs-14 text-white">Número inválido</p>
            </div>
          </div>

          <div class="col-12 col-md-6 mb-3 mt-0 mt-md-3">
            <input type="date" formControlName="date" class="form-control text-white bg-transparent border rounded-3"
              id="date" placeholder="Data de Nascimento">

            <div *ngIf="formPersonalData.controls['date'].dirty && formPersonalData.controls['date'].errors"
              class="div-msg-error mt-2">
              <img src="../../../assets/icons/icon-alert.svg" class="img-msg-error" alt="icone de atencao">
              <p class="fs-14 text-white">Data de nascimento inválida</p>
            </div>

            <div *ngIf="formPersonalData.get('date').hasError('invalidAge')" class="div-msg-error mt-2">
              <img src="../../../assets/icons/icon-alert.svg" class="img-msg-error" alt="icone de atencao">
              <p class="fs-14 text-white">A idade deve ser maior ou igual a 16 anos.</p>
            </div>
          </div>

        </div>
      </form>
    </div>

    <div class="mt-3 container">
      <p class="fs-6 mb-3 font-weight-500 text-white">Endereço</p>
      <form class="mr-5" [formGroup]="formAddress">
        <div class="row">
          <div class="col-md-12">
            <input type="text" formControlName="cep" class="form-control text-white bg-transparent border rounded-3"
              id="cep" mask="00000-000" placeholder="CEP"
              (input)="fetchAddressByCep(formAddress.controls['cep'].value)">
            <div *ngIf="formAddress.controls['cep'].dirty && formAddress.controls['cep'].errors"
              class="div-msg-error mt-2">
              <p class="fs-14 text-white">CEP inválido ou não encontrado.</p>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-6">
            <input type="text" formControlName="city" class="form-control text-white bg-transparent border rounded-3"
              id="city" placeholder="Cidade" readonly>
          </div>
          <div class="col-md-6 mt-3 mt-md-0">
            <input type="text" formControlName="uf" class="form-control text-white bg-transparent border rounded-3"
              id="uf" placeholder="Estado" readonly>
          </div>
        </div>
      </form>
    </div>

    <div class="px-2 py-4" *ngIf="!promotionEnded">
      <button type="button"
        [class]="formPersonalData.invalid || formAddress.invalid ? 'btn text-white px-4 py-3 rounded navbar-button-custom-disabled fs-14 w-100' : 'btn text-white px-4 py-3 rounded navbar-button-custom fs-14 w-100'"
        [disabled]="formPersonalData.invalid || formAddress.invalid" (click)="submitForm();">
        Continuar
      </button>
    </div>
    <div class="px-2 py-4" *ngIf="promotionEnded">
      <span class="text-danger">A promoção encerrou!</span>
    </div>
  </div>

  <div *ngIf="stepThree" class="container card-checkout p-0 rounded mt-0 mt-md-5">
    <div class="py-4 px-2 d-flex justify-content-between align-items-center">
      <div (click)="backToPayment();">
        <img class="img-fluid cursor-pointer" src="../../../assets/icons/chevron-back.svg" alt="icone de seta">
      </div>
      <div>
        <img class="img-fluid" src="../../../../assets/img/logo-white.svg" alt="logo Mundo da Sorte">
      </div>
      <div style="height: 26px; width: 26px;"></div>
    </div>

    <div class="container px-4 d-flex justify-content-between mt-4">
      <div class="d-flex flex-column align-items-center">
        <img class="img-fluid" src="../../../assets/icons/checked-icon.png" alt="icone de item verificado" width="20px">
        <p class="text-center stepper-marked-text mt-2 text-white">Escolher<br>Produto</p>
      </div>

      <div class="stepper-dots">
        ...........
      </div>

      <div class="d-flex flex-column align-items-center">
        <img class="img-fluid" src="../../../assets/icons/checked-icon.png" alt="icone de atencao" width="20px">
        <p class="text-center stepper-marked-text mt-2 text-white">Dados<br>Pessoais</p>
      </div>

      <div class="stepper-dots">
        ...........
      </div>

      <div class="d-flex flex-column align-items-center">
        <img class="img-fluid" src="../../../assets/icons/attention-icon.png" alt="icone de atencao" width="20px">
        <p class="text-center stepper-marked-text mt-2 text-white">Pagamento</p>
      </div>

      <div class="stepper-dots">
        ...........
      </div>

      <div class="d-flex flex-column align-items-center">
        <img class="img-fluid" src="../../../assets/icons/disable-icon.png" alt="icone de circulo cinza" width="20px">
        <p class="text-center stepper-disabled-text mt-2 text-white">Confirmação</p>
      </div>
    </div>

    <div *ngIf="stepOnePix">
      <div class="m-3 selected-card-chance">
        <div class="d-flex flex-sm-row flex-column justify-content-between align-items-center p-4">
          <div class="d-flex">
            <img src="../../../../../assets/img/logo-ticket.png" class="img-fluid"
              style="max-width: 50px;max-height: 50px;">
            <div class="d-flex flex-column ms-3 justify-content-center">
              <h5 class="fw-bold text-white">{{selectedCard.numberOfChances}} TÍTULOS</h5>
            </div>
          </div>
          <div class="d-flex flex-column justify-content-center"
            *ngIf="selectedCard?.value > selectedCard?.valueWithDiscount">
            <p class="text-danger fw-bold"><span style="text-decoration: line-through;">de
                {{selectedCard?.value |
                currency: 'R$ '}} </span>por</p>
            <h6 class="fw-bold text-white">{{selectedCard?.valueWithDiscount |
              currency: 'R$ '}}</h6>
          </div>
          <div class="d-flex flex-column justify-content-center"
            *ngIf="selectedCard?.value <= selectedCard?.valueWithDiscount">
            <h6 class="fw-bold text-white">{{selectedCard?.value |
              currency: 'R$ '}}</h6>
          </div>
        </div>
      </div>

      <div class="rounded-3 mt-4 p-4" style="background-color: #2B2B2B !important;">
        <div class="d-flex justify-content-between align-content-center">
          <p class="fw-bold text-white">Comprar com pix</p>
          <img src="../../../../../assets/img/pix-logo.png" alt="">
        </div>
        <p class="text-white mt-3"><i class="bi bi-clock me-3"></i> Aprovação imediata</p>
        <p class="text-white"><i class="bi bi-shield-check me-3"></i> Transação segura</p>
        <p class="text-white"><i class="bi bi-qr-code me-3"></i> Compre com facilidade</p>

        <p class="text-white">Pagar no pix é o jeito mais fácil e rápido para você contribuir e concorrer.</p>
      </div>

      <div class="container mb-2 mt-5">
        <div class="card mb-4" style="background-color: #2B2B2B !important;">
          <div class="card-header d-flex justify-content-between align-items-center">
            <div class="d-flex">
              <i class="bi bi-file-earmark-check text-white"></i>
              <p class="text-white ps-2">{{ cardConditions.title }}</p>
            </div>
            <button class="btn" (click)="toggleCard()">
              <i class="bi bi-caret-up text-white"
                [ngClass]="{'rotate': isCardExpanded, 'rotate-up': isCardExpanded}"></i>
            </button>
          </div>
          <div [class.collapse]="!isCardExpanded" [class.show]="isCardExpanded">
            <div class="px-3 justify-text py-2" style="background-color: #FFC048 !important;">
              <div class="d-flex align-items-center">
                <input type="checkbox" class="form-check" value="true" checked>
                <span class="ms-3"> Aceito os termos abaixo!</span>
              </div>
              <hr>
              <p class="fs-14">{{ cardConditions.content }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="px-2 py-4">
        <button type="button" class="btn text-white px-4 py-3 rounded navbar-button-custom w-100"
          (click)="generateQrCode();">
          Gerar Pix
        </button>
      </div>
    </div>

    <div *ngIf="stepTwoPix">

      <div class="mt-5 awaiting-payment-container mx-3 mb-3">
        <div class="d-flex justify-content-center align-items-center py-3 awaiting-payment-title">
          <img class="img-fluid" src="../../../assets/icons/clock-circle-svgrepo-com.svg" alt="icone de tempo"
            width="28px">
          <p class="ps-3 card-discount-value-text">Aguardando seu pagamento</p>
        </div>

        <div class="mt-3 mb-5">
          <p class="fs-12 font-weight-500 text-center text-white">Um código <b>Pix Copia e Cola</b> foi gerado, para
            efetivar seu
            pedido siga as instruções:</p>
        </div>

        <div class="text-center d-flex justify-content-center align-items-center">
          <div style="max-width: 450px;" class="px-4">
            <img class="img-fluid" [src]="'data:image/png;base64,' + qrCodeImage" alt="QR Code">
          </div>
        </div>

        <div class="mt-5 mb-4">
          <div class="container">
            <div class="d-flex align-content-center mb-3">
              <img class="img-fluid" src="../../../assets/icons/one-open-svgrepo-com.svg" alt="icone numero 1"
                width="18px">
              <p class="fs-12 ps-2 text-white">Clique no botão <b>“Copiar Código Pix”</b> ou <b>Leia o QrCode</b></p>
            </div>
            <div class="d-flex align-content-center mb-3">
              <img class="img-fluid" src="../../../assets/icons/two-open-svgrepo-com.svg" alt="icone numero 2"
                width="18px">
              <p class="fs-12 ps-2 text-white">Abra seu aplicativo do banco e acesse <b>“Pix”</b>.</p>
            </div>
            <div class="d-flex align-content-center mb-3">
              <img class="img-fluid" src="../../../assets/icons/three-open-svgrepo-com.svg" alt="icone numero 3"
                width="18px">
              <p class="fs-12 ps-2 text-white">Selecione a opção pagar com código <b>Pix Copia e Cola e cole</b> o
                código.</p>
            </div>
            <div class="d-flex align-content-center">
              <img class="img-fluid" src="../../../assets/icons/four-open-svgrepo-com.svg" alt="icone numero 4"
                width="18px">
              <p class="fs-12 ps-2 text-white">Após a confirmação do pagamento retorne para esta tela e <b>clique no
                  botão
                  "Confirmar Pagamento"</b></p>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-center mb-5">
          <div id="contador"
            class="px-5 py-1 counter-dotted-border counter-text font-weight-700 d-flex align-items-center">
            Tempo restante: <app-timer [stageSelect]="timerExpired" class="ms-2"></app-timer>
          </div>
        </div>

        <div class="px-2 pb-3">
          <button type="button" class="btn text-white px-4 py-3 bg-dark w-100">
            <div (click)="copyQRCode()" class="d-flex justify-content-center align-items-center">
              <img class="img-fluid" src="../../../assets/icons/copy-svgrepo-com.svg" alt="icone de copia e cola"
                width="22px">
              <p class="ps-2 text-white">{{qrCodeClipped ? "Copiado!" : "Copiar e colar"}}</p>
            </div>
          </button>
        </div>
      </div>

    </div>
  </div>

  <div *ngIf="stepFour" class="container card-checkout p-0 rounded mt-0 mt-md-5">
    <div class="py-4 px-2 d-flex justify-content-between align-items-center">
      <div (click)="backToHome();">
        <img class="img-fluid cursor-pointer" src="../../../assets/icons/home-svgrepo-com.svg" alt="icone de casa"
          width="32px">
      </div>
      <div>
        <img class="img-fluid" src="../../../../assets/img/logo-white.svg" alt="logo Mundo da Sorte">
      </div>
      <div style="height: 26px; width: 26px;"></div>
    </div>

    <div class="container px-4 d-flex justify-content-between mt-4">
      <div class="d-flex flex-column align-items-center">
        <img class="img-fluid" src="../../../assets/icons/checked-icon.png" alt="icone de item verificado" width="20px">
        <p class="text-center stepper-marked-text mt-2 text-white">Escolher<br>Produto</p>
      </div>

      <div class="stepper-dots">
        ...........
      </div>

      <div class="d-flex flex-column align-items-center">
        <img class="img-fluid" src="../../../assets/icons/checked-icon.png" alt="icone de item verificado" width="20px">
        <p class="text-center stepper-marked-text mt-2 text-white">Dados<br>Pessoais</p>
      </div>

      <div class="stepper-dots">
        ...........
      </div>

      <div class="d-flex flex-column align-items-center">
        <img class="img-fluid" src="../../../assets/icons/checked-icon.png" alt="icone de item verificado" width="20px">
        <p class="text-center stepper-marked-text mt-2 text-white">Pagamento</p>
      </div>

      <div class="stepper-dots">
        ...........
      </div>

      <div class="d-flex flex-column align-items-center">
        <img class="img-fluid" src="../../../assets/icons/checked-icon.png" alt="icone de item verificado" width="20px">
        <p class="text-center stepper-marked-text mt-2 text-white">Confirmação</p>
      </div>
    </div>

    <div class="mt-5 d-flex flex-column justify-content-center align-items-center">
      <img class="img-fluid" src="../../../assets/icons/party-popper-svgrepo-com.svg" alt="icone de comemoracao"
        width="68px">
      <p class="font-weight-400 fs-14 mt-4 text-center text-white">Caso tenha efetuado o pagamento corretamente</p>
      <p class="font-weight-600 mb-3 text-center text-white">Entraremos em contato por email em breve.</p>
      <p class="mb-5 text-center text-white">Confira seu <span class="font-weight-600">email</span> com seu(s)
        título(s).</p>
    </div>

  </div>

  <app-checkout-footer [numberStage]="stageSelect?.number"></app-checkout-footer>

</div>