<app-navbar-style-two></app-navbar-style-two>
<section class="container-fluid pt-5" style="background-color: #070813 !important;">
    <section class="container px-0 mb-5"
        style="background-color: #AEB0BB29 !important; border-radius: 8px !important; min-height: 100vh !important;"
        *ngIf="!promotionEnd">
        <div class="card-timer-custom p-4">
            <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex flex-column">
                    <h3 class="fw-bold text-white">Tempo Limitado</h3>
                    <p class="fw-light text-white">Preço promocional encerra em</p>
                </div>
                <div *ngIf="stageSelect">
                    <app-timer [stageSelect]="stageSelect"></app-timer>
                </div>
            </div>
        </div>
        <div class="d-flex flex-column p-4">
            <h1 class="text-white fw-bold">{{stageSelect?.description}}</h1>
            <img [src]="stageSelect?.banner?.location" class="img-fluid mt-4" style="max-height: 150px !important;">
            <hr color="#818181" class="my-4" style="height: 2px;">
            <h3 class="text-white fw-bold">Chances</h3>
            <div *ngFor="let item of stageSelect?.chances" #elementoArray class="mt-4 unselected-card-chance"
                (click)="chanceSelect(item)"
                [ngClass]="{'pulse': item.numberOfChances === itemComMaiorQuantidade.numberOfChances}">
                <div class="d-flex flex-sm-row flex-column justify-content-between align-items-center p-4">
                    <div class="d-flex">
                        <img src="../../../../assets/img/logo-ticket.png" class="img-fluid">
                        <div class="d-flex flex-column ms-3 justify-content-center">
                            <h2 class="fw-bold text-white">{{item.numberOfChances}} TÍTULOS</h2>
                            <p class="fw-bold text-white"><span class="buy-here-text-custom">COMPRE AQUI</span> -
                                {{item.numberOfChances}} chances de
                                ganhar</p>
                        </div>
                    </div>
                    <div class="d-flex flex-column justify-content-center" *ngIf="item.value > item.valueWithDiscount">
                        <p class="text-danger fw-bold"><span style="text-decoration: line-through;">de {{item.value |
                                currency: 'R$ '}} </span>por</p>
                        <h3 class="fw-bold text-white">{{item.valueWithDiscount |
                            currency: 'R$ '}}</h3>
                    </div>
                    <div class="d-flex flex-column justify-content-center" *ngIf="item.value <= item.valueWithDiscount">
                        <h3 class="fw-bold text-white">{{item.value |
                            currency: 'R$ '}}</h3>
                    </div>
                </div>
            </div>
            <p style="font-size: 16px;" class="text-center text-white mt-4">
                Título de Capitalização da Modalidade Filantropia Premiável de Contribuição Única emitido pela VIA
                CAPITALIZAÇÃO S.A. (VIACAP), CNPJ 88.076.302/0001-94, aprovado pelo Processo SUSEP nº
                {{stageSelect?.number}}.
                SAC 0800 740 7819. OUVIDORIA 0800 703 1989, de segunda a sexta-feira, das 8h às 17h. É proibida a venda
                de título de capitalização a menores de 16 anos. O valor não exigido dentro do prazo prescricional,
                estabelecido pela legislação em vigor, acarretará a perda desse direito. A aquisição deste título
                faculta ao adquirente a cessão de 100% do direito de resgate à A ABESS, certificada nos termos da
                legislação em vigor. Antes de contratar consulte previamente as Condições Gerais. As condições
                contratuais/regulamento deste produto protocolizadas pela sociedade junto à SUSEP poderão ser
                consultadas no endereço eletrônico www.susep.gov.br, de acordo com o número de processo constante da
                proposta. Prêmios líquidos de imposto de renda. Confira o resultado dos sorteios e as condições de
                participação em www.mundodasorteoficial.com.br. Imagens meramente ilustrativas.
            </p>
        </div>
    </section>
    <section class="container px-0 mb-5" style="background-color: #AEB0BB29 !important; border-radius: 8px !important"
        *ngIf="promotionEnd">
        <div class="card-timer-end-custom p-4">
            <div class="d-flex justify-content-center align-items-center">
                <h2 class="text-dark text-center my-3">A promoção {{stageSelect?.description}} encerrou</h2>
            </div>
        </div>
    </section>
    <div *ngIf="promotionEnd">
        <section *ngFor="let item of outhersCampaign" class="container px-0 mb-5"
            style="background-color: #AEB0BB29 !important; border-radius: 8px !important">
            <div class="card-timer-custom p-4">
                <div class="d-flex justify-content-between align-items-center">
                    <div class="d-flex flex-column">
                        <h3 class="fw-bold text-white">Tempo Limitado</h3>
                        <p class="fw-light text-white">Preço promocional encerra em</p>
                    </div>
                    <div *ngIf="item">
                        <app-timer [stageSelect]="item"></app-timer>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-column p-4">
                <h1 class="text-white fw-bold">{{item?.description}}</h1>
                <img src="../../../../assets/img/banner-default.png" class="img-fluid mt-4">
                <hr color="#818181" class="my-4" style="height: 2px;">
                <h3 class="text-white fw-bold">Chances</h3>
                <div *ngFor="let content of item?.chances" #elementoArray class="mt-4 unselected-card-chance"
                    (click)="chanceSelect(content)"
                    [ngClass]="{'pulse': content?.numberOfChances === itemComMaiorQuantidade?.numberOfChances}">
                    <div class="d-flex flex-sm-row flex-column justify-content-between align-items-center p-4">
                        <div class="d-flex">
                            <img src="../../../../assets/img/logo-ticket.png" class="img-fluid">
                            <div class="d-flex flex-column ms-3 justify-content-center">
                                <h2 class="fw-bold text-white">{{content.numberOfChances}} TÍTULOS</h2>
                                <p class="fw-bold text-white"><span class="buy-here-text-custom">COMPRE AQUI</span> -
                                    {{content.numberOfChances}} chances de
                                    ganhar</p>
                            </div>
                        </div>
                        <div class="d-flex flex-column justify-content-center"
                            *ngIf="content.value > content.valueWithDiscount">
                            <p class="text-danger fw-bold"><span style="text-decoration: line-through;">de {{item.value
                                    |
                                    currency: 'R$ '}} </span>por</p>
                            <h3 class="fw-bold text-white">{{content.valueWithDiscount |
                                currency: 'R$ '}}</h3>
                        </div>
                        <div class="d-flex flex-column justify-content-center"
                            *ngIf="content.value <= content.valueWithDiscount">
                            <h3 class="fw-bold text-white">{{content.value |
                                currency: 'R$ '}}</h3>
                        </div>
                    </div>
                </div>
                <p style="font-size: 16px;" class="text-center text-white mt-4">
                    Título de Capitalização da Modalidade Filantropia Premiável de Contribuição Única emitido pela VIA
                    CAPITALIZAÇÃO S.A. (VIACAP), CNPJ 88.076.302/0001-94, aprovado pelo Processo SUSEP nº
                    {{item?.number}}. SAC 0800 740 7819. OUVIDORIA 0800 703 1989, de segunda a sexta-feira, das 8h às
                    17h. É proibida a venda de título de capitalização a menores de 16 anos. O valor não exigido dentro
                    do prazo prescricional, estabelecido pela legislação em vigor, acarretará a perda desse direito. A
                    aquisição deste título faculta ao adquirente a cessão de 100% do direito de resgate à A ABESS,
                    certificada nos termos da legislação em vigor. Antes de contratar consulte previamente as Condições
                    Gerais. As condições contratuais/regulamento deste produto protocolizadas pela sociedade junto à
                    SUSEP poderão ser consultadas no endereço eletrônico www.susep.gov.br, de acordo com o número de
                    processo constante da proposta. Prêmios líquidos de imposto de renda. Confira o resultado dos
                    sorteios e as condições de participação em www.mundodasorteoficial.com.br. Imagens meramente
                    ilustrativas.
                </p>
            </div>
        </section>
    </div>
    <app-sponsoring-entities></app-sponsoring-entities>
    <!-- <app-capitalization-title-info></app-capitalization-title-info> -->
</section>