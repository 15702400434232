<div class="container-fluid mx-0 px-0" [style]="bannerStyle" *ngIf="showBanners === 'show'">
  <!-- spinner -->
  <div class="d-flex justify-content-center align-items-center" *ngIf="!banners">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <div id="carouselExample" class="carousel slide" data-bs-ride="carousel"
    style="height: 60vh !important;max-height: 60vh !important;" *ngIf="!isMobile">
    <div class="carousel-inner">
      <div class="carousel-item" *ngFor="let slide of banners; let isFirst = first" [class.active]="isFirst">
        <img src="{{slide.location}}" class="d-block w-100" alt="Slide" #activeImage [id]="slide.id" style="height: 60vh !important;max-height: 60vh !important;">
      </div>
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>

  <div id="carouselExample" class="carousel slide" data-bs-ride="carousel"
    style="height: 60vh !important;max-height: 60vh !important;" *ngIf="isMobile">
    <div class="carousel-inner">
      <div class="carousel-item" *ngFor="let slide of bannersMobile; let isFirst = first" [class.active]="isFirst">
        <img src="{{slide.location}}" class="d-block w-100" alt="Slide" #activeImage [id]="slide.id" style="height: 60vh !important;max-height: 60vh !important;">
      </div>
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>

</div>
<div class="d-flex justify-content-center" style="background-color: #22232e;" *ngIf="showBanners === 'show'">
  <button class="btn btn-buy-now-custom my-4" (click)="buyNow()">Compre agora</button>
</div>
<div class="container-fluid bg-default-custom" *ngIf="showBanners === 'not'"></div>