import { Component, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GenerateNewTitleModalComponent } from './generate-new-title-modal/generate-new-title-modal.component';
import { ActivatedRoute } from '@angular/router';
import { CampaignService } from 'src/services/campaign.service';

@Component({
  selector: 'app-buy',
  templateUrl: './buy.component.html',
  styleUrls: ['./buy.component.scss']
})
export class BuyComponent {

  dateDefault: any;

  selectedChance: any;

  textWarning: string = 'Título de Capitalização da Modalidade Filantropia Premiável de Contribuição Única emitido pela VIA CAPITALIZAÇÃO S.A. (VIACAP), CNPJ 88.076.302/0001-94, aprovado pelo Processo SUSEP nº 15414.623406/2023-75. SAC 0800 740 7819. OUVIDORIA 0800 703 1989, de segunda a sexta-feira, das 8h às 17h. É proibida a venda de título de capitalização a menores de 16 anos. O valor não exigido dentro do prazo prescricional, estabelecido pela legislação em vigor, acarretará a perda desse direito. A aquisição deste título faculta ao adquirente a cessão de 100% do direito de resgate à A ABESS, certificada nos termos da legislação em vigor. Antes de contratar consulte previamente as Condições Gerais. As condições contratuais/regulamento deste produto protocolizadas pela sociedade junto à SUSEP poderão ser consultadas no endereço eletrônico www.susep.gov.br, de acordo com o número de processo constante da proposta. Prêmios líquidos de imposto de renda. Confira o resultado dos sorteios e as condições de participação em www.mundodasorteoficial.com.br. Imagens meramente ilustrativas.';

  @ViewChildren('elementoArray') elementosArray: QueryList<ElementRef>;
  elementoSelecionado: ElementRef | null = null;

  itemComMaiorQuantidade = null;

  promotionEnd: boolean = false;
  selectedCampaign: any;
  stageSelect: any;

  outhersCampaign: any;

  constructor(
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private campaignService: CampaignService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.campaignService.getPublished().subscribe({
        next: data => {
          this.selectedCampaign = data;
          for (let iterator of data.stages) {
            if (iterator.id === params["id"]) {
              this.stageSelect = iterator;
            }
          }

          var dataOriginal = new Date(this.stageSelect.saleDeadline);
          dataOriginal.setHours(dataOriginal.getHours() + 3);
          if (new Date(dataOriginal) <= new Date()) {
            this.outhersCampaign = this.selectedCampaign.stages.filter(el => el.id !== this.stageSelect.id && new Date(el.saleDeadline) > new Date() && el.modality === 'luckyGlobe');
            this.promotionEnd = true;
            return;
          }
          this.greaterChance();
          this.sortArrayByNumberOfChances();
        }, error: error => {
          console.error(error);
        }
      })
    });
  }

  greaterChance() {
    this.itemComMaiorQuantidade = this.stageSelect.chances.reduce((prev, current) => {
      return (prev.numberOfChances > current.numberOfChances) ? prev : current;
    });
  }

  sortArrayByNumberOfChances() {
    this.stageSelect.chances = this.stageSelect.chances.sort((a, b) => {
      return b.numberOfChances - a.numberOfChances;
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.selectChance();
    }, 1000);
  }

  selectChance() {
    this.elementosArray.forEach(element => {
      element.nativeElement.addEventListener('click', () => {
        if (this.elementoSelecionado) {
          this.elementoSelecionado.nativeElement.classList.remove('selected-card-chance');
        }
        element.nativeElement.classList.add('selected-card-chance');
        this.elementoSelecionado = element;
      });
    });
  }

  chanceSelect(chance: any) {
    const modalRef = this.modalService.open(GenerateNewTitleModalComponent, { centered: true, backdrop: 'static' });
    let stageSelectWithChanceTemp = JSON.parse(JSON.stringify(this.stageSelect));
    for (let iterator of this.stageSelect.chances) {
      if (iterator.numberOfChances !== chance.numberOfChances) {
        stageSelectWithChanceTemp.chances = undefined;
        stageSelectWithChanceTemp.chances = chance;
        Object.assign(stageSelectWithChanceTemp, { campaingId: this.selectedCampaign._id })
      }
    }
    modalRef.componentInstance.response = stageSelectWithChanceTemp;
  }


}
