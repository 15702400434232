import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { environment } from 'src/environments/environment';
import { AssasPixCheckoutRequestDto } from 'src/dtos/assas-pix-checkout-response.dto';
import { SaleRegisterRequestDto } from 'src/dtos/sale-register-request.dto';
import { SaleGetByDocumentAndBirthdayRequestDto } from 'src/dtos/sale-get-by-document-and-birthday-request.dto';

@Injectable()
export class SaleService extends BaseService {

  private url = `${environment.api.path}/sale`;

  constructor(
    private httpClient: HttpClient
  ) {
    super();
  }

  salePixCheckout(dto: SaleRegisterRequestDto) {
    return this.httpClient
      .post(`${this.url}`, dto, this.authHeader);
  }

  getByDocumentAndBirthday(dto: SaleGetByDocumentAndBirthdayRequestDto) {
    return this.httpClient
      .get(`${this.url}/my-shopping/document/${dto.document}/birthday/${dto.birthday}`, this.anonymousHeader);
  }

  getByStageIdAndCpf(stageId: string, cpf: string) {
    return this.httpClient
      .get(`${this.url}/stage/${stageId}/cpf/${cpf}`, this.authHeader);
  }

  getById(_id) {
    return this.httpClient
      .get(`${this.url}/_id/${_id}`, this.authHeader);
  }

}
