import { io, Socket } from "socket.io-client";
import { SocketDto } from "../../dtos/socket.dto";

export abstract class BaseSocketService {

  private socketDto: SocketDto;

  constructor(socketDto: SocketDto) {
    this.socketDto = socketDto;
  }

  abstract initializeMain(): void;

  protected initializeCommonRoutine(socket: Socket): Socket {

    if (!socket)
      socket = io(
        this.socketDto.host, {
        path: this.socketDto.path,
      });

    return socket.connect();
  }

  abstract shutDownAll(): void;
}
