<div class="container-condicao text-center py-5 condicao-text">
    <p class="my-5 mx-3">
        Título de Capitalização da Modalidade Filantropia Premiável de Contribuição Única emitido pela VIA CAPITALIZAÇÃO
        S.A. (VIACAP), CNPJ
        88.076.302/0001-94, aprovado pelo Processo SUSEP nº 15414.623406/2023-75. SAC <u><a href="#">0800 740
                7819</a></u>. OUVIDORIA <u><a href="#">0800
                703 1989</a></u>, de segunda a
        sexta-feira,
        das 8h às 17h. É proibida a venda de título de capitalização a menores de 16 anos. O valor não exigido dentro do
        prazo prescricional,
        estabelecido
        pela legislação em vigor, acarretará a perda desse direito. A aquisição deste título faculta ao adquirente a
        cessão de 100% do direito de
        resgate
        à A ABESS, certificada nos termos da legislação em vigor. Antes de contratar consulte previamente as Condições
        Gerais. As condições
        contratuais/regulamento deste produto protocolizadas pela sociedade junto à SUSEP poderão ser consultadas no
        endereço eletrônico <u><a href="#">www.susep.gov.br</a></u>,
        de acordo com o número de processo constante da proposta. Prêmios líquidos de imposto de renda. Confira o
        resultado dos sorteios e as condições
        de
        participação em <u><a href="#">www.mundodasorteoficial.com.br</a></u>.
    </p>
</div>