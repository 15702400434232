<section class="container-fluid" style="background-color: #070813 !important;">
    <div class="container">
        <div class="row">
            <div class="col-12 mt-5">
                <h1 class="text-white text-uppercase">Seu pedido: <b>{{response?._id.slice(-7)}}</b></h1>
            </div>
            <div class="col-12 mt-5 pb-5">
                <div class="position-relative d-flex flex-md-row flex-column rounded-4"
                    style="background-color: #2B2B2B !important;">
                    <div class="logo-vertical-content position-relative">
                        <img src="../../../../assets/img/logo-vertical.png" alt="" class="img-fluid d-md-block d-none">
                        <img src="../../../../assets/img/logo-white.svg" alt="" class="img-fluid d-md-none d-block">
                        <div class="bal-fake-1"></div>
                        <div class="bal-fake-2"></div>
                    </div>
                    <div class="d-flex flex-column p-4 w-100">
                        <div class="d-flex justify-content-between align-items-center">
                            <p class="fw-bold text-white">Comprador</p>
                            <!-- <h4 class="text-success">Status</h4> -->
                            <p class="fw-bold" style="color: #14E018 !important;"
                                *ngIf="response.pixControl.status === 'payed'">Confirmado</p>
                            <p class="fw-bold" style="color: #EFC311 !important;"
                                *ngIf="response.pixControl.status === 'pending'">Pendente</p>
                            <p class="fw-bold" style="color: gray !important;"
                                *ngIf="response.pixControl.status === 'deactivated'">Cancelado</p>
                        </div>
                        <p class="fw-bold text-white mt-4 text-capitalize">{{maskedFullName}}</p>
                        <p class="fw-light text-white d-flex align-items-center">Celular: {{aplicarMascara}}
                        </p>
                        <p class="fw-light text-white">CPF: {{formatCpf}}</p>
                        <p class="fw-light text-white">Data/Hora do registro: {{response?.createdAt | date:
                            'dd/MM/yyyy - HH:mm' : 'UTC'}}</p>

                        <h3 class="text-white fw-bold mt-4">Valor: {{response?.pixControl?.value | currency:
                            'R$ '}}</h3>

                        <p class="fw-light text-white mt-4"><b>Sorteio:</b> {{response?.campaign.stages[0].description}}
                        </p>
                        <p class="fw-light text-white"><b>Data do sorteio: </b>
                            {{response?.campaign?.stages[0]?.drawDate
                            | date:'dd/MM/yyyy - HH:mm' : 'UTC' }}</p>

                        <p class="text-white fw-bold">Confira abaixo seu(s) título(s) gerado(s) para a sua participação:
                        </p>

                        <div *ngIf="!response.federal" class="content-bals-title">
                            <div *ngFor="let item of response.bingoCard" class="mt-3">
                                <p class="fw-bold text-white" style="font-size: 14px !important;">Título:
                                    {{item.cardNumber}}</p>
                                <div class="d-flex align-items-center flex-wrap">
                                    <div *ngFor="let content of item.numbers" class="ball-title-custom">{{content}}
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div *ngIf="response.federal" class="content-bals-title">
                            <div *ngFor="let item of response.federal">
                                <div class="d-flex align-items-center flex-wrap">
                                    <div *ngFor="let content of item.numbers" class="ball-title-custom">{{content}}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <p class="fw-bold text-white mt-5">O sorteio será dia {{response?.campaign?.stages[0]?.drawDate
                            | date: 'dd/MM/yyyy - HH:mm' : 'UTC'}}.</p>

                        <p class="fw-light text-white">Acessa nosso grupo do Whatsapp para poder ficar por dentro
                            e receber o resultado do sorteio.</p>
                        <button class="btn btn-redirect-wpp mt-4 no-print"><i class="bi bi-whatsapp text-white me-3"></i>Clique
                            aqui para entrar no grupo</button>
                        <button class="btn btn-download-pdf mt-3 no-print" (click)="openPrintDialog()"><i
                                class="bi bi-filetype-pdf text-white me-3"></i>Baixar em PDF</button>

                        <div class="container-participe rounded-4 mt-4 no-print">

                            <div class="row align-items-center justify-content-center py-5 m-0">
                                <div class="col-lg-4 d-flex justify-content-center align-items-center mb-4 mb-lg-0">
                                    <div>
                                        <div class="text-center text-white mb-2">
                                            Entidade beneficiada
                                        </div>
                                        <div class="d-flex justify-content-center mb-3">
                                            <img class="img-fluid" src="../../../../../assets/icons/ABESS-dark.svg"
                                                alt="Entidade beneficiada ABESS">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 d-flex justify-content-center align-items-center mb-4 mb-lg-0">
                                    <div>
                                        <div class="text-center text-white mb-2">
                                            Título emitido por
                                        </div>
                                        <div class="d-flex justify-content-center mb-3">
                                            <img class="img-fluid" src="../../../../../assets/icons/viacap-dark.svg"
                                                alt="Título emitido por ViaCap">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 d-flex justify-content-center align-items-center mb-4 mb-lg-0">
                                    <div>
                                        <div class="text-center text-white mb-2">
                                            Distribuído por
                                        </div>
                                        <div class="d-flex justify-content-center mb-3">
                                            <img class="img-fluid" src="../../../../../assets/icons/logo-black.svg"
                                                alt="Distribuído por Mundo da Sorte">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <p class="fw-light text-white mt-4 no-print">Você esta adquirindo um título de capitalização cujo
                            direito
                            de
                            resgate será revertido para a fundação Abess, certificada nos termos da regulamentação
                            vigente, caso não haja comunicação a sociedade de capitalização, sobre a desistência da
                            cessão, até o dia anterior a realização do primeiro sorteio previsto no título de
                            capitalização.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>