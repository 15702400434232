<section>
    <app-navbar-style-two></app-navbar-style-two>
    <div class="content-style-custom">
        <img src="../../../../assets/img/my-titles-banner.svg" alt="" class="bg-faq-custom" (load)="onImageLoad()">
        <h3 class="title-custom-absolute">Pesquisar meus títulos</h3>
    </div>
    <div class="container-fluid" style="background-color: #070813 !important;">
        <h1 class="text-white fw-bold text-center pt-5">Meus titulos</h1>
        <div class="container" style="min-height: 100vh !important;">
            <form [formGroup]="form">
                <div class="row p-4 " style="background-color: #2B2B2B !important;border-radius: 20px !important;">
                    <div class="col-12 mt-4">
                        <h5 class="fw-bold text-white">Escolha o sorteio e digite seu número de CPF</h5>
                    </div>
                    <div class="col-md-6 mt-4">
                        <label for="" class="fw-bold text-white">Selecione o sorteio</label>
                        <ng-select [items]="stages" notFoundText="Sorteio inexistente" bindLabel="description"
                            bindValue="id" formControlName="prizeDraw" placeholder="Selecione..."
                            class="bg-transparent">
                        </ng-select>
                    </div>
                    <div class="col-md-6 mt-4">
                        <label for="" class="fw-bold text-white">CPF</label>
                        <div class="input-group mb-3">
                            <span class="input-group-text bg-transparent" style="border-right: none !important;"><i
                                    class="bi bi-person-vcard text-white"></i></span>
                            <input type="text" class="form-control bg-transparent text-white border-white"
                                placeholder="000.000.000-00" aria-describedby="basic-addon1" mask="000.000.000-00"
                                style="border-left: none !important; border-radius: 0px 8px 8px 0px;"
                                formControlName="cpf">
                        </div>
                    </div>
                    <div class="col-12 d-flex justify-content-end mt-4">
                        <button class="btn btn-search-my-titles" (click)="submit()" [disabled]="form.invalid">
                            <div class="spinner-border" role="status" *ngIf="searching">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <span *ngIf="!searching">
                                Buscar
                            </span>
                        </button>
                    </div>
                </div>
            </form>
            <div *ngIf="response.length !== 0" class="row p-4 mt-5"
                style="background-color: #2B2B2B !important;border-radius: 20px !important;">
                <div *ngFor="let item of response"
                    class="col-12 d-flex justify-content-between border border-white rounded-3 py-2 mt-3">
                    <div class="d-flex align-items-center">
                        <div class="ball-status-my-title">
                            <i class="bi bi-ticket-detailed text-white mb-1" style="max-height: 18px;"></i>
                        </div>
                        <div class="d-flex flex-column ms-3">
                            <p class="text-white fw-bold">{{buyerData.name}} - {{buyerData.phone}}</p>
                            <p class="fw-light text-white" style="font-size: 10px;">Data: {{item.createdAt | date:
                                'dd/MM/yyyy - HH:mm' : 'UTC'}}</p>
                        </div>
                    </div>
                    <div class="d-flex align-items-center">
                        <p class="fw-bold" style="color: #14E018 !important;"
                            *ngIf="item.pixControl.status === 'payed'">Confirmado</p>
                        <p class="fw-bold" style="color: #EFC311 !important;"
                            *ngIf="item.pixControl.status === 'pending'">Pendente</p>
                        <p class="fw-bold" style="color: gray !important;"
                            *ngIf="item.pixControl.status === 'deactivated'">Cancelado</p>
                        <p class="text-white ms-3 fw-light cursor-pointer" (click)="viewTitle(item._id)"><i
                                class="bi bi-printer me-2 cursor-pointer"></i>Ver
                            titulo</p>
                    </div>
                </div>
            </div>
            <div *ngIf="notTitles" class="pb-5">
                <div class="d-flex justify-content-center bg-warning px-3 py-3 mx-2 rounded mt-3">
                    <p class="font-weight-700">Você não possui titulos para esse sorteio!</p>
                </div>
            </div>
        </div>
    </div>
</section>