import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { SaleService } from "src/services/sale.service";

@Injectable()
export class SaleByIdResolve implements Resolve<any> {

    constructor(
        private saleService: SaleService,
    ) { }

    resolve(route: ActivatedRouteSnapshot) {
        const result = this.saleService.getById(route.params['_id']);
        return result;
    }
}