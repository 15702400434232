import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { CampaignInterface } from "src/dtos/campaign/campaign.interface";

@Injectable()
export class CampaignService extends BaseService {
  private url = `${environment.api.path}/campaign`;

  constructor(private httpClient: HttpClient) {
    super();
  }

  getAllCampaigns(): Observable<CampaignInterface[]> {
    return this.httpClient.get(`${this.url}`, this.authHeader).pipe(map(this.extractData, this.serviceError));
  }

  getStages(): Observable<any[]> {
    return this.httpClient.get(`${this.url}/list`, this.authHeader).pipe(map(this.extractData, this.serviceError));
  }

  getPublished(): Observable<CampaignInterface> {
    return this.httpClient.get(`${this.url}/published`, this.authHeader).pipe(map(this.extractData, this.serviceError));
  }

  getFinished(): Observable<CampaignInterface> {
    return this.httpClient.get(`${this.url}/finished`, this.authHeader).pipe(map(this.extractData, this.serviceError));
  }

  getLastWinner(): Observable<any> {
    return this.httpClient.get(`${this.url}/last-winner`, this.authHeader).pipe(map(this.extractData, this.serviceError));
  }
}
