import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class BingoService extends BaseService {

    private url = `${environment.api.path}/bingoCard`;

    constructor(
        private httpClient: HttpClient
    ) {
        super();
    }

    generateCard(quantity: number) {
        return this.httpClient
            .post(`${this.url}/generate-card/${quantity}`, null, this.authHeader);
    }
    regenerateCard(arrIds: any) {
        return this.httpClient
            .post(`${this.url}/regenerate-card/`, arrIds, this.authHeader);
    }
}