<section class="faq-area">
    <app-navbar-style-two></app-navbar-style-two>
    <div class="content-style-custom">
        <img src="../../../../assets/img/faq.svg" alt="" class="bg-faq-custom" (load)="onImageLoad()">
        <h3 class="title-custom-absolute">Perguntas frequentes</h3>
    </div>
    <div class="container-fluid py-5" style="background-color: #070813;">
        <div class="container">
            <div class="my-4">
                <h4 class="text-white fw-bold">Mundo da Sorte é Seguro?</h4>
            </div>
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="card px-4 py-3">
                        <div class="d-flex flex-row justify-content-between align-items-center cursor-pointer pt-1"
                            (click)="isCollapsed[0] = !isCollapsed[0]">
                            <p>Quem pode participar?</p>
                            <i [ngClass]="isCollapsed[0]?'bi bi-chevron-down':'bi bi-chevron-up'"></i>
                        </div>
                        <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed[0]">
                            <div>
                                <div class="card-body">Qualquer pessoa física brasileira, acima de 16 anos de idade, que
                                    aceite nossos Termos de Uso. Vendemos para todo o Brasil.</div>
                            </div>
                        </div>
                    </div>
                    <div class="card px-4 py-3 mt-3">
                        <div class="d-flex flex-row justify-content-between align-items-center cursor-pointer pt-1"
                            (click)="isCollapsed[1] = !isCollapsed[1]">
                            <p>Esse Sorteio é Legal?</p>
                            <i [ngClass]="isCollapsed[1]?'bi bi-chevron-down':'bi bi-chevron-up'"></i>
                        </div>
                        <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed[1]">
                            <div>
                                <div class="card-body">Sim a Mundo da Sorte é Títulos de Capitalização de Pagamento
                                    Único, da Modalidade Filantropia Permeável, emitidos pela VIA CAPITALIZAÇÃO S/A.,
                                    inscrita no CNPJ sob no 88.076.302/0001-94, aprovados de acordo com Processo SUSEP,
                                    numero: 15414.623384/2023-43 - Os Prêmios são pagos em dinheiro líquidos ou seja
                                    você recebe o valor já livre de impostos.</div>
                            </div>
                        </div>
                    </div>
                    <div class="card px-4 py-3 mt-3">
                        <div class="d-flex flex-row justify-content-between align-items-center cursor-pointer pt-1"
                            (click)="isCollapsed[2] = !isCollapsed[2]">
                            <p>Como são feitos os sorteios?</p>
                            <i [ngClass]="isCollapsed[2]?'bi bi-chevron-down':'bi bi-chevron-up'"></i>
                        </div>
                        <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed[2]">
                            <div>
                                <div class="card-body">Os sorteios são realizados com base na extração da Loteria
                                    Federal.</div>
                            </div>
                        </div>
                    </div>
                    <div class="card px-4 py-3 mt-3">
                        <div class="d-flex flex-row justify-content-between align-items-center cursor-pointer pt-1"
                            (click)="isCollapsed[3] = !isCollapsed[3]">
                            <p>Quais são as formas de pagamentos?</p>
                            <i [ngClass]="isCollapsed[3]?'bi bi-chevron-down':'bi bi-chevron-up'"></i>
                        </div>
                        <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed[3]">
                            <div>
                                <div class="card-body">Você compra o seu título com pagamentos via PIX e Boleto
                                    Bancário.</div>
                            </div>
                        </div>
                    </div>
                    <div class="card px-4 py-3 mt-3">
                        <div class="d-flex flex-row justify-content-between align-items-center cursor-pointer pt-1"
                            (click)="isCollapsed[4] = !isCollapsed[4]">
                            <p>Tem outras dúvidas?</p>
                            <i [ngClass]="isCollapsed[4]?'bi bi-chevron-down':'bi bi-chevron-up'"></i>
                        </div>
                        <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed[4]">
                            <div>
                                <div class="card-body">Fique tranquilo! Basta entrar em contato através do nosso
                                    WhatsApp (11) 91212-4004</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-participe">
        <div class="row align-items-center justify-content-center py-4 m-0">
            <div class="col-lg-4 d-flex justify-content-center align-items-center mb-4 mb-lg-0">
                <div>
                    <div class="text-center text-white mb-2">
                        Entidade beneficiada
                    </div>
                    <div class="d-flex justify-content-center mb-3">
                        <img class="img-fluid" src="../../../../../assets/icons/ABESS-dark.svg"
                            alt="Entidade beneficiada ABESS">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 d-flex justify-content-center align-items-center mb-4 mb-lg-0">
                <div>
                    <div class="text-center text-white mb-2">
                        Título emitido por
                    </div>
                    <div class="d-flex justify-content-center mb-3">
                        <img class="img-fluid" src="../../../../../assets/icons/viacap-dark.svg"
                            alt="Título emitido por ViaCap">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 d-flex justify-content-center align-items-center mb-4 mb-lg-0">
                <div>
                    <div class="text-center text-white mb-2">
                        Distribuído por
                    </div>
                    <div class="d-flex justify-content-center mb-3">
                        <img class="img-fluid" src="../../../../../assets/icons/logo-black.svg"
                            alt="Distribuído por Mundo da Sorte">
                    </div>
                </div>
            </div>
        </div>
        <div class="container-condicao text-center py-5 condicao-text">
            <p class="my-5 mx-3">
                Título de Capitalização da Modalidade Filantropia Premiável de Contribuição Única emitido pela VIA
                CAPITALIZAÇÃO
                S.A. (VIACAP), CNPJ
                88.076.302/0001-94, aprovado pelo Processo SUSEP nº 15414.623406/2023-75. SAC <u><a href="#">0800 740
                        7819</a></u>. OUVIDORIA <u><a href="#">0800
                        703 1989</a></u>, de segunda a
                sexta-feira,
                das 8h às 17h. É proibida a venda de título de capitalização a menores de 16 anos. O valor não exigido
                dentro do
                prazo prescricional,
                estabelecido
                pela legislação em vigor, acarretará a perda desse direito. A aquisição deste título faculta ao
                adquirente a
                cessão de 100% do direito de
                resgate
                à A ABESS, certificada nos termos da legislação em vigor. Antes de contratar consulte previamente as
                Condições
                Gerais. As condições
                contratuais/regulamento deste produto protocolizadas pela sociedade junto à SUSEP poderão ser
                consultadas no
                endereço eletrônico <u><a href="#">www.susep.gov.br</a></u>,
                de acordo com o número de processo constante da proposta. Prêmios líquidos de imposto de renda. Confira
                o
                resultado dos sorteios e as condições
                de
                participação em <u><a href="#">www.mundodasorteoficial.com.br</a></u>.
            </p>
        </div>
    </div>
</section>
<!-- End FAQ Area -->