import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CampaignService } from 'src/services/campaign.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  selectedCampaign: any;
  stageSelect: any;

  constructor(
    private router: Router,
    private campaignService: CampaignService
  ) { }

  ngOnInit(): void {
    this.campaignService.getPublished().subscribe({
      next: data => {
        this.selectedCampaign = data;
      }, error: error => {
        console.error(error);
      }
    })
  }

  goToBuy(item) {
    if (item.modality === 'luckyGlobe') {
      this.router.navigate([`buy/${item.id}`])
    } else {
      this.router.navigate([`checkout/${item.id}`])
    }
  }

}
