import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-title-detail',
  templateUrl: './title-detail.component.html',
  styleUrls: ['./title-detail.component.scss']
})
export class TitleDetailComponent {

  arrNumbers: any = [];

  numerosAleatorios: number[] = [];

  response: any;

  constructor(
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    let response = this.route.snapshot.data['resolve'];
    this.response = response['data'];
    this.response.campaign.stages = this.response.campaign.stages.filter(el => el.id === this.response.stageId);

  }

  gerarNumerosAleatorios() {
    this.numerosAleatorios = [];

    while (this.numerosAleatorios.length < 20) {
      const numeroAleatorio = Math.floor(Math.random() * 61);
      if (!this.numerosAleatorios.includes(numeroAleatorio)) {
        this.numerosAleatorios.push(numeroAleatorio);
      }
    }
    this.arrNumbers = this.numerosAleatorios
  }

  get maskedFullName(): string {
    const parts = this.response.buyerData.name.split(' ');
    const maskedLastName = parts[1][0].padEnd(parts[1].length, '*');
    return `${parts[0]} ${maskedLastName}`;
  }

  get aplicarMascara(): string {
    const parte1 = this.response.buyerData.phone.slice(0, 2);
    const parte2 = this.response.buyerData.phone.slice(2, 7);
    const parte3 = this.response.buyerData.phone.slice(7);
    return `(${parte1}) ${parte2}-${parte3}`;
  }

  get formatCpf(): string {
    const parte1 = this.response.buyerData.cpf.substring(0, 3);
    const parte2 = this.response.buyerData.cpf.substring(3, 6);
    const parte3 = '***';
    const parte4 = '***';
    return `${parte1}.${parte2}.${parte3}-${parte4}`;

  }

  openPrintDialog() {
    window.print();
  }

}
