import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { map } from 'rxjs/operators';
import { LoginModalComponent } from './login-modal/login-modal.component';
import { CampaignService } from 'src/services/campaign.service';

@Component({
  selector: 'app-navbar-style-two',
  templateUrl: './navbar-style-two.component.html',
  styleUrls: ['./navbar-style-two.component.scss']
})
export class NavbarStyleTwoComponent implements OnInit {
  isMobile: boolean = false;
  pdfUrl!: SafeResourceUrl;
  modalRef!: NgbModalRef;
  regulamentoText = 'Visualizar Regulamento';
  condicoesGeraisText = 'Visualizar Condições Gerais';
  modalTitle: string = '';
  response: any;

  constructor(
    private modalService: NgbModal,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private spinner: NgxSpinnerService,
    private campaignService: CampaignService
  ) { }

  ngOnInit(): void {
    this.campaignService.getPublished().subscribe({
      next: data => {
        this.response = data;

      }
    })
    this.checkScreenSize();
  }

  openPdf(type: string) {
    if (type === 'pdf1') {
      window.open(this.response.regulation, 'blank')
    }
    if (type === 'pdf2') {
      window.open(this.response.generalConditions, 'blank')
    }
  }


  checkScreenSize() {
    this.isMobile = window.innerWidth < 991;
  }

  isMobileView() {
    return this.isMobile;
  }

  openModal(content: TemplateRef<any>, pdfType: string) {
    this.spinner.show();

    this.getPdfUrl(pdfType).subscribe((url: SafeResourceUrl) => {
      this.pdfUrl = url;

      if (pdfType === 'pdf1') {
        this.modalTitle = 'Visualizar Regulamento';
      } else if (pdfType === 'pdf2') {
        this.modalTitle = 'Visualizar Condições Gerais';
      }

      this.modalRef = this.modalService.open(content, { centered: true, scrollable: true, size: 'xl', backdrop: 'static' });
      this.spinner.hide();
    });
  }

  openModalQuemSomos(content: TemplateRef<any>) {
    this.modalRef = this.modalService.open(content, { centered: true, scrollable: true, size: 'lg', backdrop: 'static' });
  }

  getPdfUrl(pdfType: string) {
    let firebaseStorageUrl = '';
    console.log(pdfType);

    if (pdfType === 'pdf1') {
      firebaseStorageUrl = this.response.regulation;
    } else if (pdfType === 'pdf2') {
      firebaseStorageUrl = 'https://firebasestorage.googleapis.com/v0/b/bucketpublicplay/o/mundodasorte%2Fcondi%C3%A7%C3%B5es-gerais.pdf?alt=media&token=99a6055f-3715-4091-a2db-34d21816d531';
    }

    if (pdfType === 'regulamento') {
      this.modalTitle = this.regulamentoText;
    } else if (pdfType === 'condicoes-gerais') {
      this.modalTitle = this.condicoesGeraisText;
    }

    return this.http.get(firebaseStorageUrl, { responseType: 'blob' }).pipe(
      map((blob: Blob) => {
        const file = new Blob([blob], { type: 'application/pdf' });
        const fileUrl = URL.createObjectURL(file);
        return this.sanitizer.bypassSecurityTrustResourceUrl(fileUrl);
      })
    );
  }

  verifyLogin() {
    this.modalService.open(LoginModalComponent, { centered: true })
  }

}
