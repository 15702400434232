import { Injectable } from "@angular/core";
import { BaseSocketService } from "./base.socket.service";
import { Socket } from "socket.io-client";
import { SocketDto } from "../../dtos/socket.dto";
import { environment } from "../../environments/environment";
import { MessageGatewayEventsEnum } from "../../dtos/message-gateway-events.enum";
import { ResponseDto } from "../../dtos/response.dto";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class MessageSocketService extends BaseSocketService {

    private _socket: Socket | undefined;

    saleIds = new BehaviorSubject<string>('');

    constructor(
    ) {
        super(
            new SocketDto(
                environment.api.socket,
                MessageGatewayEventsEnum.PATH.toString(),
            ));
    }

    initializeMain(): void {
        this._socket = this.initializeCommonRoutine(this._socket!);
    }

    requestSaleConfirmationResponse() {
        // this._socket!.emit(MessageGatewayEventsEnum.CONFIRM_PAYMENT_REQUEST);
        this._socket!.on(MessageGatewayEventsEnum.CONFIRM_PAYMENT_RESPONSE, (response: ResponseDto) => {
            if (response.success)
                this.saleIds.next(response.data.saleId);
        });
    }

    shutDownAll(): void {
        if (!!this._socket && this._socket.connected) {
            this._socket.offAny()
            this._socket.removeAllListeners();
            this._socket.close();
            this._socket.disconnect();
        }
    }
}