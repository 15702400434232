import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { environment } from 'src/environments/environment';
import { AssasPixCheckoutRequestDto } from 'src/dtos/assas-pix-checkout-response.dto';

@Injectable()
export class PaymentService extends BaseService {

  private url = `${environment.api.path}/payment`;

  constructor(
    private httpClient: HttpClient
  ) {
    super();
  }

  assasPixCheckout(dto: AssasPixCheckoutRequestDto) {
    return this.httpClient
      .post(`${this.url}/assas/checkout/pix`, dto, this.anonymousHeader);
  }

}
