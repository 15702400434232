<div class="container pt-5">
    <div class="d-flex justify-content-center">
        <img src="../../../../assets/logomundo.svg" style="max-height: 40px;" (click)="back()" class="cursor-pointer">
    </div>
    <div class="row justify-content-center mt-4">
        <div class="col-md-6">
            <p class="fw-bold">Informações da compra: </p>
            <div class="accordion accordion-flush" id="accordionFlushExample">
                <div class="accordion-item" *ngFor="let item of response; let i = index;">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            [attr.data-bs-target]="'#collapse' + i" aria-expanded="false">
                            Pedido id <span class="text-uppercase">&nbsp;{{item._id.slice(-7)}} - {{item.createdAt |
                                date: 'dd/MM/yyyy HH:mm'}}</span>
                        </button>
                    </h2>
                    <div [attr.id]="'collapse' + i" class="accordion-collapse collapse">
                        <div class="accordion-body">
                            <p><b>Número do pedido:</b> {{item._id.slice(-7)}}</p>
                            <p><b>Data da compra:</b> {{item.createdAt |
                                date: 'dd/MM/yyyy HH:mm'}}</p>
                            <p *ngIf="item.campaign"><b>Data do sorteio:</b> {{item.drawDate |
                                date: 'dd/MM/yyyy HH:mm'}}</p>
                            <p><b>Valor total:</b> {{item.pixControl?.value | currency: 'R$ '}}</p>
                            <p><b>Forma de pagamento:</b> Pix</p>
                            <p><b>Situação:</b>
                                <span *ngIf="item.pixControl?.status === 'pending'"
                                    class="text-warning">&nbsp;Pendente</span>
                                <span *ngIf="item.pixControl?.status === 'payed'" class="text-success">&nbsp;Pago</span>
                                <span *ngIf="item.pixControl?.status === 'deactivated'"
                                    class="text-danger">&nbsp;Desativado</span>
                            </p>
                            <hr>
                            <p>Números</p>
                            <div class="d-flex align-items-center">
                                <div *ngFor="let number of item.numbers" class="circle-for-number-draw">
                                    {{number}}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<div class="py-3" style="background-color: #111827 !important;">
    <app-checkout-footer></app-checkout-footer>
</div>