<div class="d-flex" *ngIf="!promotionEnded">
    <p *ngIf="timerDisplay.days" class="text-white text-center me-2 font-weight-700">
        {{ timerDisplay.days }}
        <br>
        <span class="timer-unit fs-14 font-weight-500">Dias</span>
    </p>

    <p *ngIf="timerDisplay.hours" class="text-white text-center me-2 font-weight-700">
        {{ timerDisplay.hours }}
        <br>
        <span class="timer-unit fs-14 font-weight-500">Horas</span>
    </p>

    <p class="text-white me-2 font-weight-700">
        {{ timerDisplay.minutes }}
        <br>
        <span class="timer-unit fs-14 font-weight-500" *ngIf="stageSelect.description">Min</span>
    </p>

    <p class="text-white font-weight-700">
        {{ timerDisplay.seconds }}
        <br>
        <span class="timer-unit fs-14 font-weight-500" *ngIf="stageSelect.description">Seg</span>
    </p>
</div>