import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SaleService } from 'src/services/sale.service';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss']
})
export class LoginModalComponent {

  form: FormGroup;

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private router: Router,
    private saleService: SaleService,
    private ngxSpinnerService: NgxSpinnerService,
    private toastrService: ToastrService
  ) {
    this.form = this.formBuilder.group({
      cpf: ['', [Validators.required]],
      dataNascimento: ['', [Validators.required]]
    })
  }

  exit() {
    this.modalService.dismissAll()
  }

  submit() {

    this.ngxSpinnerService.show();
    let request = {
      document: this.form.controls['cpf'].value,
      birthday: this.formatarDataParaBackend(this.form.controls['dataNascimento'].value)
    }

    this.saleService.getByDocumentAndBirthday(request).subscribe({
      next: data => {
        const response = data['data'];

        if (response.length !== 0) {
          this.exit();
          this.router.navigate([`my-shopping/${request.document}/${request.birthday}`]);
          this.ngxSpinnerService.hide();
        } else {
          this.toastrService.error('Não registro com os dados inseridos', '', { progressBar: true })
          this.ngxSpinnerService.hide();
          this.exit();
        }
      },
      error: error => {
        console.error(error);
        this.ngxSpinnerService.hide();
      }
    })

  }

  formatarDataParaBackend(data: string): string {
    const dataObjeto: Date = new Date(data);

    if (isNaN(dataObjeto.getTime())) {
      throw new Error('Data inválida. Certifique-se de que a data esteja no formato "YYYY-MM-DD".');
    }

    const dataFormatada: string = dataObjeto.toISOString().slice(0, 19) + '.000+0000';
    return dataFormatada;
  }
}
