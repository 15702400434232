import { AfterViewInit, Component, ElementRef, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CampaignService } from 'src/services/campaign.service';
import { SaleService } from 'src/services/sale.service';

@Component({
  selector: 'app-my-titles',
  templateUrl: './my-titles.component.html',
  styleUrls: ['./my-titles.component.scss']
})
export class MyTitlesComponent implements AfterViewInit {

  form: FormGroup;

  searching: boolean = false;

  stages: any = [];

  response: any = [];
  buyerData: any;

  notTitles: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private campaignService: CampaignService,
    private saleService: SaleService,
    private el: ElementRef,
    private renderer: Renderer2
  ) {
    this.form = this.formBuilder.group({
      prizeDraw: ['', [Validators.required]],
      cpf: ['', [Validators.required]]
    })
  }

  ngOnInit(): void {
    this.campaignService.getStages().subscribe({
      next: data => {
        this.stages = data;
      }
    })
  }

  ngAfterViewInit() {
    this.resizeImageContainer();
    window.addEventListener('resize', () => this.resizeImageContainer());
  }

  onImageLoad() {
    this.resizeImageContainer();
  }

  resizeImageContainer() {
    const container = this.el.nativeElement.querySelector('.content-style-custom');
    const image = this.el.nativeElement.querySelector('.bg-faq-custom');

    if (container && image) {
      this.renderer.setStyle(container, 'height', `${image.clientHeight}px`);
    }
  }

  viewTitle(saleId: string) {
    this.router.navigate([`title/${saleId}`])
  }

  submit() {
    this.saleService.getByStageIdAndCpf(this.form.controls['prizeDraw'].value, this.form.controls['cpf'].value).subscribe({
      next: data => {
        if (data['data'].length === 0) {
          this.notTitles = true;
          return
        }
        this.response = data['data'];
        this.buyerData = this.response[0].buyerData
      },
      error: error => {
        console.error(error)
      }
    })
  }

}
