<section class="container-fluid" style="background-color: #070813 !important;">
  <div class="container py-5">
    <div class="row justify-content-center">
      <div class="col-12 d-flex justify-content-center">
        <h1 class="fw-bold text-white my-5">
          Prêmios
        </h1>
      </div>
      <div *ngFor="let item of selectedCampaign?.stages" class="col-8 mt-3">
        <div class="row mt-3 p-md-4 p-2"
          style="border: 1px solid #2B2B2B !important; background-color: #AEB0BB29 !important;border-radius: 20px !important;">
          <div class="col-lg-3 d-flex justify-content-center">
            <img src="../../../../assets/img/awards-default.png" class="img-fluid">
          </div>
          <div class="col-lg-9">
            <h3 class="fw-bold text-white">{{item.description}}</h3>
            <p class="text-white mb-3" style="font-size: 16px;">Lista de Prêmios</p>
            <div class="text-white d-flex" *ngFor="let content of item.prizes; let i = index;">
              <div class="ball-fake-award-number">
                <span class="fw-bold pb-1" style="color: #00BE04;font-size: 12px;max-height: 13px;">{{i + 1}}</span>
              </div>
              &nbsp;{{content.quantity}} pix no valor de {{content.value |
              currency: 'R$'}}
            </div>
            <div
              class="d-flex justify-content-md-between align-items-md-center justify-content-center flex-md-row flex-column">
              <div class="d-flex flex-column">
                <p class="text-white">Data do sorteio:</p>
                <p class="fw-bold text-white">{{item.drawDate | date: 'dd/MM/yyyy'}} às {{item.drawDate | date:
                  'HH:mm' }}</p>
              </div>
              <button class="btn btn-buy-now" (click)="goToBuy(item)">Compre agora</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>