<section class="containter-fluid p-4">
    <div class="d-flex justify-content-end">
        <i class="bi bi-x-lg cursor-pointer" (click)="exit()"></i>
    </div>
    <div class="d-flex justify-content-center">
        <img src="../../../../../assets/logomundo.svg" style="max-height: 40px;">
    </div>
    <h6 class="my-4">Para visualizar dos dados da sua compra, informe seu CPF e sua data de nascimento</h6>
    <form [formGroup]="form">
        <label for="cpf">CPF</label>
        <input type="text" id="cpf" class="form-control" formControlName="cpf" mask="000.000.000-00">

        <label for="data-de-nascimento" class="mt-4">Data de Nascimento</label>
        <input type="date" id="data-de-nascimento" class="form-control" formControlName="dataNascimento">
    </form>

    <div class="d-flex justify-content-center mt-5">
        <button class="btn btn-outline-success px-4" (click)="submit()" [disabled]="form.invalid">Ver compras</button>
    </div>
</section>