<section class="faq-area">
  <app-navbar-style-two></app-navbar-style-two>
  <div class="img-fluid position-relative">
      <h3 class="title-absolute">Ganhadores</h3>
      <img src="../../../../assets/img/winner.svg" class="img-fluid">
  </div>
  <div class="container-fluid py-5" style="background-color: #f5f5f5;">
      <div class="container">
          <div class="my-4">
              <h4>Mundo da Sorte é Seguro?</h4>
          </div>
          <div class="row">
              <div class="col-lg-12 col-md-12">
                  <div class="card px-4 py-3 mt-4" *ngFor="let item of isCollapsed; let idx = index">
                      <div class="d-flex flex-row justify-content-between align-items-center cursor-pointer pt-1"
                          (click)="isCollapsed[idx] = !isCollapsed[idx]">
                          <p>Concorra a 10 pix</p>
                          <div class="d-flex flex-row justify-content-end align-items-center">
                            <p class="date-text mx-2">Data do sorteio: 09/08/2023</p>
                            <i [ngClass]="isCollapsed[idx]?'bi bi-chevron-down':'bi bi-chevron-up'"></i>
                          </div>
                      </div>
                      <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed[idx]">
                          <div>
                              <div class="card-body">Numero 12. Ganhador: João Felipe</div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <div class="container-participe">
      <div class="row align-items-center justify-content-center py-4 m-0">
          <div class="col-lg-4 d-flex justify-content-center align-items-center mb-4 mb-lg-0">
              <div>
                  <div class="text-center text-white mb-2">
                      Entidade beneficiada
                  </div>
                  <div class="d-flex justify-content-center mb-3">
                      <img class="img-fluid" src="../../../../../assets/icons/ABESS-dark.svg"
                          alt="Entidade beneficiada ABESS">
                  </div>
              </div>
          </div>

          <div class="col-lg-4 d-flex justify-content-center align-items-center mb-4 mb-lg-0">
              <div>
                  <div class="text-center text-white mb-2">
                      Título emitido por
                  </div>
                  <div class="d-flex justify-content-center mb-3">
                      <img class="img-fluid" src="../../../../../assets/icons/viacap-dark.svg"
                          alt="Título emitido por ViaCap">
                  </div>
              </div>
          </div>

          <div class="col-lg-4 d-flex justify-content-center align-items-center mb-4 mb-lg-0">
              <div>
                  <div class="text-center text-white mb-2">
                      Distribuído por
                  </div>
                  <div class="d-flex justify-content-center mb-3">
                      <img class="img-fluid" src="../../../../../assets/icons/logo-black.svg"
                          alt="Distribuído por Mundo da Sorte">
                  </div>
              </div>
          </div>
      </div>
      <div class="container-condicao text-center py-5 condicao-text">
          <p class="my-5 mx-3">
              Título de Capitalização da Modalidade Filantropia Premiável de Contribuição Única emitido pela VIA
              CAPITALIZAÇÃO
              S.A. (VIACAP), CNPJ
              88.076.302/0001-94, aprovado pelo Processo SUSEP nº 15414.623406/2023-75. SAC <u><a href="#">0800 740
                      7819</a></u>. OUVIDORIA <u><a href="#">0800
                      703 1989</a></u>, de segunda a
              sexta-feira,
              das 8h às 17h. É proibida a venda de título de capitalização a menores de 16 anos. O valor não exigido
              dentro do
              prazo prescricional,
              estabelecido
              pela legislação em vigor, acarretará a perda desse direito. A aquisição deste título faculta ao
              adquirente a
              cessão de 100% do direito de
              resgate
              à A ABESS, certificada nos termos da legislação em vigor. Antes de contratar consulte previamente as
              Condições
              Gerais. As condições
              contratuais/regulamento deste produto protocolizadas pela sociedade junto à SUSEP poderão ser
              consultadas no
              endereço eletrônico <u><a href="#">www.susep.gov.br</a></u>,
              de acordo com o número de processo constante da proposta. Prêmios líquidos de imposto de renda. Confira
              o
              resultado dos sorteios e as condições
              de
              participação em <u><a href="#">www.mundodasorteoficial.com.br</a></u>.
          </p>
      </div>
  </div>
</section>
<!-- End FAQ Area -->