import { Component, OnInit } from "@angular/core";
import { CampaignService } from "src/services/campaign.service";

@Component({
  selector: "app-home-demo-ten",
  templateUrl: "./home-demo-ten.component.html",
  styleUrls: ["./home-demo-ten.component.scss"],
})
export class HomeDemoTenComponent implements OnInit {
  banners = [];

  constructor(private campaignService: CampaignService) {}

  ngOnInit(): void {
    // this.campaignService.getAllCampaigns().subscribe(data => {
    //   this.banners = data.map(e => e.stages.map(e => e.banner?.location).toString());
    // });
  }
}
