import { Component, HostListener } from '@angular/core';
import { CampaignService } from 'src/services/campaign.service';

@Component({
  selector: 'app-last-winner',
  templateUrl: './last-winner.component.html',
  styleUrls: ['./last-winner.component.scss']
})
export class LastWinnerComponent {

  videoWidth: string = '';
  videoHeight: string = '';

  winner: any;

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.changeVideoResolution()
  }

  constructor(
    private campaignService: CampaignService
  ) { }

  ngOnInit(): void {

    this.campaignService.getLastWinner().subscribe({
      next: data => {
        if (Object.keys(data).length !== 0) {
          this.winner = data;
          this.winner.campaign.stages = this.winner.campaign.stages.filter(el => el.id === this.winner.stageId);
        }
      }
    })

    this.videoWidth = '100%';
    this.videoHeight = '395px';



  }

  changeVideoResolution() {
  }

  get maskedFullName(): string {
    const parts = this.winner.sale.buyerData.name.split(' ');
    const maskedLastName = parts[1][0].padEnd(parts[1].length, '*');
    return `${parts[0]} ${maskedLastName}`;
  }

  get maskPhone(): string {
    const parte1 = this.winner.sale.buyerData.phone.slice(0, 2);
    const parte2 = this.winner.sale.buyerData.phone.slice(2, 7);
    const parte3 = this.winner.sale.buyerData.phone.slice(7);
    return `(${parte1}) ${parte2}-${parte3}`;
  }

}
