import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { NotFoundComponent } from "./components/pages/not-found/not-found.component";
import { FaqComponent } from "./components/pages/faq/faq.component";
import { LoginComponent } from "./components/pages/login/login.component";
import { HomeDemoTenComponent } from "./components/pages/home-demo-ten/home-demo-ten.component";
import { CheckoutComponent } from "./components/pages/checkout/checkout.component";
import { MyShoppingComponent } from "./components/pages/my-shopping/my-shopping.component";
import { MyShoppingGetByDocumentAndBirthdayResolve } from "src/resolvers/my-shopping-get-by-document-birthday.resolver";
import { WinnersComponent } from "./components/pages/winners/winners.component";
import { ResultsComponent } from "./components/pages/results/results.component";
import { BuyComponent } from "./components/pages/buy/buy.component";
import { MyTitlesComponent } from "./components/pages/my-titles/my-titles.component";
import { TitleDetailComponent } from "./components/pages/title-detail/title-detail.component";
import { SaleByIdResolve } from "src/resolvers/sale-by-id.resolver";

const routes: Routes = [
  { path: "", component: HomeDemoTenComponent },
  { path: "index-10", component: HomeDemoTenComponent },
  { path: "faq", component: FaqComponent },
  { path: "winners", component: WinnersComponent },
  { path: "login", component: LoginComponent },
  { path: "results", component: ResultsComponent },
  { path: "my-titles", component: MyTitlesComponent },
  { path: "checkout/:id", component: CheckoutComponent },
  { path: "title/:_id", component: TitleDetailComponent, resolve: { resolve: SaleByIdResolve } },
  { path: "buy/:id", component: BuyComponent },
  {
    path: "my-shopping/:document/:birthday",
    component: MyShoppingComponent,
    resolve: { resolve: MyShoppingGetByDocumentAndBirthdayResolve },
  },
  // Here add new pages component

  { path: "**", component: NotFoundComponent }, // This line will remain down from the whole pages component list
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
