import { Component, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent {

  timeRemaining: number = 10 * 60;

  timer: any;
  remainingTime: number = 10 * 60;
  timerDisplay: { minutes: string; seconds: string; days?: string; hours?: string } = { minutes: "10", seconds: "00" };

  promotionEnded: boolean = false;


  @Input() stageSelect: any;

  constructor(
    private toastrService: ToastrService
  ) { }

  ngOnInit(): void {

    const startDate = new Date().getTime();
    var dataOriginal = new Date(this.stageSelect.saleDeadline);

    dataOriginal.setHours(dataOriginal.getHours() + 3);
    const endDate = new Date(dataOriginal).getTime();
    this.remainingTime = Math.abs(endDate - startDate);
    this.startTimer();
  }

  updateTimer() {
    const days = Math.floor(this.remainingTime / (1000 * 60 * 60 * 24));
    const hours = Math.floor((this.remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((this.remainingTime % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((this.remainingTime % (1000 * 60)) / 1000);

    const formattedDays = days ? `${days}` : "";
    const formattedHours = hours ? `${hours}` : "";
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes.toString();
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds.toString();

    this.timerDisplay = {
      minutes: formattedMinutes,
      seconds: formattedSeconds,
      days: formattedDays,
      hours: formattedHours,
    };

    this.remainingTime = this.remainingTime - 1000;

    if (this.remainingTime < 0) {
      this.stopTimer();
      this.promotionEnded = true;
      if (!this.stageSelect.description) {
        this.toastrService.error('O tempo para o pagamento do pix expirou!', '', { progressBar: true })
      }
    }
  }

  updateTimerOuther(stageId) { }

  startTimer() {
    this.timer = setInterval(() => {
      this.updateTimer();
    }, 1000);
  }

  stopTimer() {
    clearInterval(this.timer);
  }


}
