import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-my-shopping',
  templateUrl: './my-shopping.component.html',
  styleUrls: ['./my-shopping.component.scss']
})
export class MyShoppingComponent implements OnInit {

  response: any = []

  constructor(
    private route: ActivatedRoute,
    private location: Location
  ) { }

  ngOnInit(): void {
    let response = this.route.snapshot.data['resolve'];
    this.response = response['data'];

    for (let iterator of this.response) {
      if (iterator.campaign !== null) {
        for (let item of iterator.campaign.stages) {
          if (iterator.stageId === item.id) {
            Object.assign(iterator, { drawDate: item.drawDate })
          }
        }
      }
    }

  }

  back() {
    this.location.back();
  }


}
