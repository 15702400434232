import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-checkout-footer',
  templateUrl: './checkout-footer.component.html',
  styleUrls: ['./checkout-footer.component.scss']
})
export class CheckoutFooterComponent {

  @Input() numberStage: any;

}
