<section>
    <app-navbar-style-two></app-navbar-style-two>
    <div class="content-style-custom">
        <img src="../../../../assets/img/ganhadores.png" alt="" class="bg-faq-custom" (load)="onImageLoad()">
        <h3 class="title-custom-absolute">Pesquisar meus títulos</h3>
    </div>
    <div class="container-fluid px-0" style="background-color: #070813 !important;">
        <div class="container" style="min-height: 100vh !important;">
            <div class="row">
                <div class="col-12 mt-5">
                    <h4 class="fw-bold text-white mb-4">Conheça os ganhadores do Mundo da Sorte!</h4>
                </div>
                <div class="accordion accordion-flush" id="accordionFlushExample">
                    <div class="accordion-item" *ngFor="let item of response; let i = index;"
                        style="background-color: transparent !important;">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed text-white pe-5" type="button"
                                data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse' + i" aria-expanded="false"
                                style="background-color: transparent !important;">
                                <div class="d-flex justify-content-between align-items-center"
                                    style="min-width: 100% !important;">
                                    <span>
                                        Campanha <span class="text-uppercase">&nbsp;{{item?._id.slice(-7)}} -
                                            {{item?.name}}</span>
                                    </span>
                                    <span class="me-3">Data da campanha{{item?.publishDate | date: '
                                        dd/MM/yyyy'}}</span>
                                </div>
                            </button>
                        </h2>
                        <div [attr.id]="'collapse' + i" class="accordion-collapse collapse">
                            <div class="accordion-body">
                                <div class="d-flex">
                                    <h3 class="fw-bold text-white">Confira as etapas dessa campanha
                                    </h3>
                                </div>
                                <div *ngFor="let content of item?.stages;" class="">
                                    <div *ngIf="content.win.length !== 0">
                                        <div class="d-flex justify-content-between align-items-center"
                                            style="min-width: 100% !important;">
                                            <span class="text-white">
                                                Etapa <span class="text-uppercase">&nbsp;{{content?.id.slice(-7)}} -
                                                    {{content?.description}}</span>
                                            </span>
                                            <span class="me-3 text-white">Data do sorteio{{content?.drawDate | date: '
                                                dd/MM/yyyy'}}</span>
                                        </div>
                                        <div class="d-flex mt-3">
                                            <h3 class="fw-bold text-white">Confira os
                                                vencedores dessa etapa
                                            </h3>
                                        </div>
                                        <div *ngFor="let saleWin of content?.win" class="p-3 d-flex flex-column mt-2">
                                            <span class="text-white">{{saleWin?.prize?.title}}</span>
                                            <span class="text-white">{{saleWin?.buyerData.name}}</span>
                                            <span class="text-white">Titulo: {{saleWin?.drawNumber}}</span>
                                            <hr>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-participe">

        <div class="row align-items-center justify-content-center py-5 m-0">
            <div class="col-lg-4 d-flex justify-content-center align-items-center mb-4 mb-lg-0">
                <div>
                    <div class="text-center text-white mb-2">
                        Entidade beneficiada
                    </div>
                    <div class="d-flex justify-content-center mb-3">
                        <img class="img-fluid" src="../../../../../assets/icons/ABESS-dark.svg"
                            alt="Entidade beneficiada ABESS">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 d-flex justify-content-center align-items-center mb-4 mb-lg-0">
                <div>
                    <div class="text-center text-white mb-2">
                        Título emitido por
                    </div>
                    <div class="d-flex justify-content-center mb-3">
                        <img class="img-fluid" src="../../../../../assets/icons/viacap-dark.svg"
                            alt="Título emitido por ViaCap">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 d-flex justify-content-center align-items-center mb-4 mb-lg-0">
                <div>
                    <div class="text-center text-white mb-2">
                        Distribuído por
                    </div>
                    <div class="d-flex justify-content-center mb-3">
                        <img class="img-fluid" src="../../../../../assets/icons/logo-black.svg"
                            alt="Distribuído por Mundo da Sorte">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>