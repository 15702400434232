<!-- Start Footer Area -->
<section class="container-fluid py-5" style="background-color: #070813 !important;">
    <div class="row">
        <div class="d-flex flex-md-row flex-column justify-content-center align-items-center">
            <p></p>
        </div>
    </div>
    <div class="d-flex flex-column justify-content-center align-items-center">
        <div class="d-flex flex-md-row flex-column justify-content-center align-items-center my-3">

            <p class="fw-bold text-white" style="font-size: 20px;"><img src="../../../../assets/img/instagram-icon.svg"
                    alt="" class="me-2">mundodasorteoficial</p>

            <p class="fw-bold text-white mx-md-5" style="font-size: 20px;"> <span
                    style="font-size: 32px !important;">@</span> mundodasorteoficial.com.br</p>

            <p class="fw-bold text-white" style="font-size: 20px;"><img src="../../../../assets/img/linkedin-icon.png"
                    alt="" class="me-2">mundodasorteoficial</p>

        </div>
        <img src="../../../../assets/img/logo-white.svg" alt="" height="69">
    </div>
</section>