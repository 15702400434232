import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { SaleService } from "../services/sale.service";

@Injectable()
export class MyShoppingGetByDocumentAndBirthdayResolve implements Resolve<any> {

    constructor(
        private saleService: SaleService,
    ) { }

    resolve(route: ActivatedRouteSnapshot) {
        const result = this.saleService.getByDocumentAndBirthday({ document: route.params['document'], birthday: route.params['birthday'] });
        return result;
    }
}