import { Component, HostListener, Input, ViewChild, ElementRef } from "@angular/core";
import { Router } from "@angular/router";
import { NgbCarouselConfig, NgbSlideEvent } from "@ng-bootstrap/ng-bootstrap";
import { CampaignInterface } from "src/dtos/campaign/campaign.interface";
import { CampaignService } from "src/services/campaign.service";

@Component({
  selector: "app-business-solutions",
  templateUrl: "./business-solutions.component.html",
  styleUrls: ["./business-solutions.component.scss"],
})
export class BusinessSolutionsComponent {
  showNavigationArrows = true;
  showNavigationIndicators = false;
  imagesMobile = ["../../../assets/icons/banner-mobile.png"];

  isMobile: boolean = false;

  @Input() banners: string[];
  @Input() bannersMobile: string[];

  @ViewChild('activeImage') activeImage: ElementRef<HTMLImageElement>;

  campanhaRespone: CampaignInterface;

  imgActive: string = '';

  activeImageIndex: number = 0;

  showBanners: string = '';
  showBannersMobile: string = '';

  bannerStyle: string = '';

  constructor(
    config: NgbCarouselConfig,
    private campaignService: CampaignService,
    private router: Router
  ) {
    config.showNavigationArrows = true;
    config.showNavigationIndicators = true;
  }

  ngOnInit() {
    this.checkScreenSize();
    this.campaignService.getPublished().subscribe({
      next: data => {
        this.campanhaRespone = data
        let banners: any = []; 
        let bannersMobile: any = []; 
        if (Object.keys(data).length !== 0) {
          for (let iterator of data.stages) {
            banners.push({ location: iterator.banner?.location, id: iterator.id })
            bannersMobile.push({ location: iterator.bannerMobile?.location, id: iterator.id })
          }
          this.showBanners = 'show'
          this.showBannersMobile = 'show'
        } else {
          this.showBanners = 'not'
          this.showBannersMobile = 'show'
        }
        this.banners = banners;
        this.bannersMobile = bannersMobile;
      },
      error: error => {
        console.error(error);
      }
    })
  }

  buyNow() {
    const image = document.getElementsByClassName('active');
    let stageSelect: any;
    for (let iterator of this.campanhaRespone.stages) {
      if (iterator.id === image[0].children[0].id) {
        stageSelect = iterator
      }
    }
    if (stageSelect.modality === 'luckyGlobe') {
      this.router.navigate([`buy/${image[0].children[0].id}`])
    } else {
      this.router.navigate([`checkout/${image[0].children[0].id}`])
    }
  }

  @HostListener("window:resize")
  onWindowResize() {
    this.checkScreenSize();
  }

  checkScreenSize() {
    this.isMobile = window.innerWidth < 991;
  }

  setBannerStyle(): void {
    if (!this.isMobileView()) {
      this.bannerStyle = 'max-height: 400px;';
    } else {
      this.bannerStyle = '';
    }
  }

  isMobileView() {
    return this.isMobile;
  }
}
