<div class="container-video py-5">
  <div class="d-flex flex-column flex-md-row justify-content-center py-5">
    <div class="col-md-4">
      <div class="d-flex justify-content-center align-items-center">
        <iframe id="videoFrame" [width]="videoWidth" [height]="videoHeight"
          src="https://www.youtube.com/embed/8aZJKVBhE84" frameborder="0" allowfullscreen></iframe>
      </div>
      <h5 class="text-center text-white pt-3 pb-5 px-5">Assista ao vídeo oficial para descobrir como participar e
        ter<br>a chance de concorrer a um<br>mundo de prêmios.</h5>
    </div>
    <div class="col-md-5 h-100 d-flex justify-content-md-start align-items-md-start flex-column">
      <div class="px-3">
        <h3 class="text-white">Como participar? É simples!</h3>
        <div class="d-flex flex-row my-3">
          <img src="../../../assets/icons/participe-1-icon copy.svg" height="68" class="img-resize-mobile">
          <div class="d-flex flex-column">
            <h4 class="px-3 text-white">Escolha o Seu Título Mundo da Sorte</h4>
            <p class="text-white px-3">Você pode escolher quantos títulos quiser! Quanto mais selecionar, mais chances
              você tem de ganhar.</p>
          </div>
        </div>
        <div class="d-flex flex-row my-3">
          <img src="../../../assets/icons/participe-2-icon copy.svg" height="68" class="img-resize-mobile">
          <div class="d-flex flex-column">
            <h4 class="px-3 text-white">Preencha seus dados e escolha a sua forma de pagamento:</h4>
            <p class="text-white px-3">Fácil, seguro e com várias opções! Você pode pagar via pix,
              cartão de crédito ou boleto.</p>
          </div>
        </div>
        <div class="d-flex flex-row">
          <img src="../../../assets/icons/participe-3-icon copy.svg" height="68" class="img-resize-mobile">
          <div class="d-flex flex-column">
            <h4 class="px-3 text-white">Faça seu pagamento:</h4>
            <p class="text-white px-3">Agora você já está participando. O seu título Mundo da Sorte vai chegar no
              e-mail! Além disso, você pode consultar em "Consultar Meus Títulos".</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>