<app-navbar-style-two></app-navbar-style-two>
<div style="background-color: #070813 !important;">

  <app-business-solutions [banners]="banners"></app-business-solutions>
  <app-login></app-login>
  <app-video-container></app-video-container>
  <app-last-winner></app-last-winner>

  <div class="container-fluid" style="background-color: #070813 !important;">
    <div class="container pt-5">
      <div class="d-flex justify-content-center align-items-center pb-3">
        <span class="sub-title">&nbsp;</span>
        <h1 class="fw-bold" style="color: #00BE04;">Entidade <span>Beneficiada</span></h1>
      </div>
      <div class="row align-items-center">
        <div class="col-lg-6 d-flex justify-content-center">
          <img src="assets/crianca.webp" alt="image" class="img-fluid">
        </div>

        <div class="col-lg-6 col-md-12">
          <div class="crypto-about-content">
            <div class="d-flex justify-content-center mt-md-0 mt-4">
              <img src="../../../../assets/img/logo-abess.svg" class="img-fluid" alt="image">
            </div>
            <p class="mt-2 text-white text-center mt-5">A ABESS conta com pessoas que visam transformar a realidade das
              nossas crianças, com mais
              oportunidades, saúde e qualidade
              de vida. São associados que, engajados na causa, estão em busca de um ensino de qualidade e na esperança
              de
              um mundo melhor.</p>

          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="container-fluid py-5 mt-5" style="background-color: #AEB0BB29 !important;">
    <div class="mobile-app-content">
      <h3 class="text-white">Baixe nosso <span>APP</span></h3>
      <p class="text-white">Saia na frente e compre e participe pelo nosso APP.</p>

      <div class="app-btn-box">
        <a href="#" class="playstore-btn" target="_blank">
          <img src="assets/img/cryptocurrency-home/google-play.png" alt="image">
          Baixar
          <span>Google Play</span>
        </a>
        <a href="#" class="applestore-btn" target="_blank">
          <img src="../../../../assets/img/apple-icon.png" style="height: 40px !important;width: 40px !important;">
          Baixar
          <span>Apple Store</span>
        </a>
      </div>
    </div>

  </div>
  <!-- End Crypto About Area -->

  <!-- Start Earning Platform Area -->
  <!-- <div class="earning-platform-area pt-100 pb-70" id="whitelabel">
    <app-faq></app-faq>
  </div> -->

  <app-sponsoring-entities></app-sponsoring-entities>
  <app-capitalization-title-info></app-capitalization-title-info>

</div>