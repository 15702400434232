import { Component, ElementRef, HostListener } from '@angular/core';

@Component({
  selector: 'app-video-container',
  templateUrl: './video-container.component.html',
  styleUrls: ['./video-container.component.scss']
})
export class VideoContainerComponent {
  videoWidth: number = 0;
  videoHeight: number = 0;
  aspectRatio: number = 16 / 9;
  maxVideoWidth: number = 222;

  constructor(private elementRef: ElementRef) { }

  ngOnInit() {
    this.calculateVideoSize();
  }

  @HostListener('window:resize')
  onWindowResize() {
    this.calculateVideoSize();
  }

  calculateVideoSize() {
    const containerWidth = this.elementRef.nativeElement.offsetWidth;

    this.videoWidth = Math.min(containerWidth, this.maxVideoWidth);
    this.videoHeight = this.videoWidth / this.aspectRatio;
  }
}
