import { HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { throwError } from "rxjs";
import { environment } from "src/environments/environment";
import CryptoUtil from "../utils/crypto.util";

export abstract class BaseService {
  protected get anonymousHeader() {
    return {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
  }

  protected get authHeader() {
    const username = environment.api.username;
    const password = environment.api.password;
    const encodedCredentials = btoa(`${username}:${password}`);
    return {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Basic ${encodedCredentials}`,
      }),
    };
  }

  protected extractData(response: any) {
    return response.data || {};
  }

  protected serviceError(response: Response | any) {
    let customError: string[] = [];
    let customResponse = new Error();
    if (response instanceof HttpErrorResponse) {
      if (response.statusText === "Unknown Error") {
        customError.push("Unknown Error");
        response.error.errors = customError;
      }
    }
    if (response.status === 500) {
      customError.push("Error processing request");
      customResponse.error.errors = customError;
      return throwError(customResponse);
    }
    return throwError(response);
  }

  protected encrypt(data: string) {
    return { payload: CryptoUtil.encrypt(environment.encrypt_key, data) };
  }

  protected decrypt(data: any) {
    return CryptoUtil.decrypt(environment.encrypt_key, data);
  }
}
class Error {
  error: ErrorResponse = new ErrorResponse();
}
class ErrorResponse {
  errors: string[] = [];
}
