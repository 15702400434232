import { AfterViewInit, Component, ElementRef, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit, AfterViewInit {

  isCollapsed = new Array(5).fill(true);

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) { }

  ngAfterViewInit() {
    this.resizeImageContainer();
    window.addEventListener('resize', () => this.resizeImageContainer());
  }

  onImageLoad() {
    this.resizeImageContainer();
  }

  resizeImageContainer() {
    const container = this.el.nativeElement.querySelector('.content-style-custom');
    const image = this.el.nativeElement.querySelector('.bg-faq-custom');

    if (container && image) {
      this.renderer.setStyle(container, 'height', `${image.clientHeight}px`);
    }
  }

  ngOnInit() {
  }

}
