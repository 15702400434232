import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { AppComponent } from "./app.component";
import { PreloaderComponent } from "./components/layouts/preloader/preloader.component";
import { FooterComponent } from "./components/layouts/footer/footer.component";
import { NotFoundComponent } from "./components/pages/not-found/not-found.component";
import { FaqComponent } from "./components/pages/faq/faq.component";
import { BusinessSolutionsComponent } from "./components/pages/business-solutions/business-solutions.component";
import { LoginComponent } from "./components/pages/login/login.component";
import { HomeDemoTenComponent } from "./components/pages/home-demo-ten/home-demo-ten.component";
import { NavbarStyleTwoComponent } from "./components/layouts/navbar-style-two/navbar-style-two.component";
import { CheckoutComponent } from "./components/pages/checkout/checkout.component";
import { CheckoutFooterComponent } from "./components/pages/checkout/components/checkout-footer/checkout-footer.component";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { AppRoutingModule } from "./app-routing.module";
import { ReactiveFormsModule } from "@angular/forms";
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";
import { HttpClientModule } from "@angular/common/http";
import { ToastrModule } from "ngx-toastr";
import { CommonModule } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxScrollTopModule } from "ngx-scrolltop";
import { PaymentService } from "src/services/payment.service";
import { SaleService } from "src/services/sale.service";
import { CampaignService } from "src/services/campaign.service";
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { VideoContainerComponent } from './components/pages/video-container/video-container.component';
import { LoginModalComponent } from './components/layouts/navbar-style-two/login-modal/login-modal.component';
import { MyShoppingComponent } from './components/pages/my-shopping/my-shopping.component';
import { MyShoppingGetByDocumentAndBirthdayResolve } from "src/resolvers/my-shopping-get-by-document-birthday.resolver";
import { WinnersComponent } from './components/pages/winners/winners.component';
import { TimerComponent } from './components/pages/checkout/timer/timer.component';
import { ResultsComponent } from './components/pages/results/results.component';
import { LastWinnerComponent } from './components/pages/last-winner/last-winner.component';
import { BuyComponent } from './components/pages/buy/buy.component';
import { SponsoringEntitiesComponent } from './components/pages/sponsoring-entities/sponsoring-entities.component';
import { CapitalizationTitleInfoComponent } from './components/pages/capitalization-title-info/capitalization-title-info.component';
import { GenerateNewTitleModalComponent } from './components/pages/buy/generate-new-title-modal/generate-new-title-modal.component';
import { MyTitlesComponent } from './components/pages/my-titles/my-titles.component';
import { TitleDetailComponent } from './components/pages/title-detail/title-detail.component';
import { BingoService } from "src/services/bingo.service";
import { NgSelectModule } from "@ng-select/ng-select";
import { SaleByIdResolve } from "src/resolvers/sale-by-id.resolver";

@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
    FooterComponent,
    NotFoundComponent,
    FaqComponent,
    BusinessSolutionsComponent,
    LoginComponent,
    HomeDemoTenComponent,
    NavbarStyleTwoComponent,
    CheckoutComponent,
    CheckoutFooterComponent,
    VideoContainerComponent,
    LoginModalComponent,
    MyShoppingComponent,
    WinnersComponent,
    TimerComponent,
    ResultsComponent,
    LastWinnerComponent,
    BuyComponent,
    SponsoringEntitiesComponent,
    CapitalizationTitleInfoComponent,
    GenerateNewTitleModalComponent,
    MyTitlesComponent,
    TitleDetailComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    ReactiveFormsModule,
    NgxMaskDirective,
    NgxMaskPipe,
    HttpClientModule,
    ToastrModule.forRoot(),
    CommonModule,
    NgbModule,
    NgxScrollTopModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    NgSelectModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [provideNgxMask(), PaymentService, SaleService, CampaignService, MyShoppingGetByDocumentAndBirthdayResolve, BingoService, SaleByIdResolve],
  bootstrap: [AppComponent]
})
export class AppModule { }
