import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbAccordionModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BingoService } from 'src/services/bingo.service';
import { SaleService } from 'src/services/sale.service';
import { MessageSocketService } from 'src/services/socket/message.socket.service';

@Component({
  selector: 'app-generate-new-title-modal',
  templateUrl: './generate-new-title-modal.component.html',
  styleUrls: ['./generate-new-title-modal.component.scss'],
})
export class GenerateNewTitleModalComponent {

  response: any;

  arrNumbers: any = [];

  numerosAleatorios: number[] = [];
  step1: boolean = true;
  step2: boolean = false;
  step3: boolean = false;
  step4: boolean = false;
  step5: boolean = false;

  formUserData: FormGroup;

  qrCodeLoading: boolean = true;
  qrCodeClipped: boolean = false;


  cardConditions = {
    title: "Condições e Termos de Uso",
    content:
      "Na qualidade de Subscritor deste titulo de capitalização de Contribuição da Modalidade Filantropia Premiável, emitido pela VIA CAPITALIZAÇÃO S.A. (VIACAP), declaro que: I. Recebi li e entendi as Condições Gerais deste titulo de capitalização de Contribuição onde concordo com os seus termos e autorizo sem ônus, a utilização de meu nome, voz e imagem para divulgação desta campanha; II. Tenho conhecimento de que a contratação da presente proposta de subscrição implica na automática adesão a todos os termos das condições Gerais do titulo de capitalização de Contribuição e que este documenta encontra-se ao meu dispor no site: www.mundodasorteoficial.com.br.",
  }

  isCardExpanded: boolean = false;

  arrowImage = "../../../assets/icons/seta-icon.svg";

  numbersResponseLoading: boolean = true;

  qrCodeImage: string = "";
  qrCodePayload: string = "";
  timeRemaining: number = 10 * 60;
  timerExpired: any = {
    saleDeadline: new Date()
  }

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private http: HttpClient,
    private bingoService: BingoService,
    private saleService: SaleService,
    private toastrService: ToastrService,
    private messageSocketService: MessageSocketService,
    private renderer: Renderer2,
  ) {

    const regex = new RegExp(/^[a-zA-ZÀ-Ÿ][A-zÀ-ÿ']+\s([a-zA-zÀ-ÿ']\s?)*[a-zA-ZÀ-Ÿ][a-zA-zÀ-ÿ']+$/);
    this.formUserData = this.formBuilder.group({
      name: ["", [Validators.required, Validators.maxLength(50), Validators.pattern(regex), Validators.minLength(5)]],
      cpf: ["", [Validators.required, Validators.minLength(11)]],
      email: [
        "",
        [Validators.required, Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")],
      ],
      phone: ["", [Validators.required, Validators.minLength(11)]],
      date: ["", [Validators.required, Validators.minLength(8), this.validateAge]],
      cep: ["", [Validators.required]],
      city: ["", [Validators.required]],
      uf: ["", [Validators.required]],
    })
  }

  ngOnInit(): void {

    this.bingoService.generateCard(this.response.chances.numberOfChances).subscribe({
      next: data => {
        this.numbersResponseLoading = false;
        this.arrNumbers = data['data'];
      },
      error: error => {
        console.error(error);
      }
    })

    this.formUserData.controls['cep'].valueChanges.subscribe({
      next: data => {
        if (data.length === 8) {
          this.fetchAddressByCep(data);
        }
      }
    })

  }



  regenerateNumbers() {
    let arrIds = { cardsIds: [] };
    for (let iterator of this.arrNumbers) {
      arrIds.cardsIds.push(iterator._id)
    }
    this.bingoService.regenerateCard(arrIds).subscribe({
      next: data => {
        this.arrNumbers = data['data'];
      },
      error: error => {
        console.error(error);

      }
    })
  }

  validateAge(control: any): { [key: string]: any } | null {
    if (!control.value) return null;
    const birthDate = new Date(control.value);

    const currentDate = new Date();
    let difference = currentDate.getTime() - birthDate.getTime();
    let age = Math.floor(difference / 31557600000);

    if (age < 16) {
      return { invalidAge: true };
    }
    return null;
  }


  exit() {
    this.modalService.dismissAll();
  }

  next(value) {
    switch (value) {
      case 1:
        this.step1 = true;
        this.step2 = false;
        this.step3 = false;
        this.step4 = false;
        this.step5 = false;
        break;
      case 2:
        this.step1 = false;
        this.step2 = true;
        this.step3 = false;
        this.step4 = false;
        this.step5 = false;
        this.getValuesForm()
        break;
      case 3:
        this.step1 = false;
        this.step2 = false;
        this.step3 = true;
        this.step4 = false;
        this.step5 = false;
        this.setForm();
        break;
      case 4:
        this.step1 = false;
        this.step2 = false;
        this.step3 = false;
        this.step4 = true;
        this.step5 = false;
        this.generatePixQrCode();
        this.timerExpired.saleDeadline = new Date(this.add10minutes());
        break;
      case 5:
        this.step1 = false;
        this.step2 = false;
        this.step3 = false;
        this.step4 = false;
        this.step5 = true;
        break;
      default:
        break;
    }
  }

  add10minutes() {
    const dataAtual = new Date();
    const novaData = new Date(dataAtual.getTime() + 10 * 60000);

    novaData.setHours(novaData.getHours() - 3);

    const dataFormatada = novaData.toISOString();

    return dataFormatada;
  }

  setForm() {
    localStorage.setItem('formData', JSON.stringify(this.formUserData.value))
  }

  getValuesForm() {
    if (localStorage.getItem('formData') !== null) {
      let form: any = localStorage.getItem('formData');
      form = JSON.parse(form);
      this.formUserData.patchValue({
        name: form.name,
        cpf: form.cpf,
        email: form.email,
        phone: form.phone,
        date: form.date,
        cep: form.cep,
        city: form.city,
        uf: form.uf,
      })
    }
  }

  fetchAddressByCep(cep: string) {
    if (cep && cep.length === 8) {
      this.spinner.show();
      this.http.get(`https://viacep.com.br/ws/${cep}/json/`).subscribe((response: any) => {
        if (!response.erro) {
          this.formUserData.patchValue({
            city: response.localidade,
            uf: response.uf,
          });

          this.spinner.hide();
        } else {
          this.formUserData.get("cep")?.setErrors({ cepInvalid: true });
          this.spinner.hide();
        }
      });
    }
  }

  validarCPF(cpf: string) {
    cpf = cpf.replace(/[^\d]+/g, "");
    if (cpf == "") return false;
    // Elimina CPFs invalidos conhecidos
    if (
      cpf.length != 11 ||
      cpf == "00000000000" ||
      cpf == "11111111111" ||
      cpf == "22222222222" ||
      cpf == "33333333333" ||
      cpf == "44444444444" ||
      cpf == "55555555555" ||
      cpf == "66666666666" ||
      cpf == "77777777777" ||
      cpf == "88888888888" ||
      cpf == "99999999999"
    )
      return false;
    // Valida 1o digito
    let add = 0;
    for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
    let rev = 11 - (add % 11);
    if (rev == 10 || rev == 11) rev = 0;
    if (rev != parseInt(cpf.charAt(9))) return false;
    // Valida 2o digito
    add = 0;
    for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
    rev = 11 - (add % 11);
    if (rev == 10 || rev == 11) rev = 0;
    if (rev != parseInt(cpf.charAt(10))) return false;
    return true;
  }

  toggleCard() {
    this.isCardExpanded = !this.isCardExpanded;
  }

  generatePixQrCode() {

    let buyerData = {
      name: this.formUserData.controls['name'].value,
      email: this.formUserData.controls['email'].value,
      cpf: this.formUserData.controls['cpf'].value,
      gender: null,
      phone: this.formUserData.controls['phone'].value,
      dateOfBirth: this.formUserData.controls['date'].value,
      address: {
        zipCode: this.formUserData.controls['cep'].value,
        city: this.formUserData.controls['city'].value,
        state: this.formUserData.controls['uf'].value,
      }
    }

    let arrIds = { cardsIds: [] };
    for (let iterator of this.arrNumbers) {
      arrIds.cardsIds.push(iterator._id)
    }

    const dto = {
      stageId: this.response.id,
      campaignId: this.response.campaingId,
      buyerData: buyerData,
      cardsIds: arrIds,
      chancesNumbers: this.response.chances.numberOfChances
    };

    this.saleService.salePixCheckout(dto).subscribe({
      next: (data: any) => {
        this.toastrService.success("Pix gerado com sucesso!");
        this.qrCodeImage = (data as { data: { pixQrCode: string } }).data.pixQrCode;
        this.qrCodePayload = (data as { data: { pixPayload: string } }).data.pixPayload;
        this.qrCodeLoading = false;
        localStorage.setItem("saleId", data.data._id);

        this.messageSocketService.requestSaleConfirmationResponse();

        this.messageSocketService.saleIds.subscribe({
          next: (response: string) => {
            const saleIdLocal = localStorage.getItem("saleId");
            if (response === saleIdLocal) {
              localStorage.removeItem("saleId");
              this.confirmPayment();
            }
          },
        });

        this.spinner.hide();
      },
      error: error => {
        console.error(error);
        this.toastrService.error("Não foi possível gerar o QR Code, tente novamente mais tarde!");
        this.spinner.hide();
      },
    });
  }

  confirmPayment() {
    this.spinner.show();

    setTimeout(() => {
      this.next(5)
      this.spinner.hide();
    }, 2000);
  }

  formatarTempoRestante(): string {
    const minutes = Math.floor(this.timeRemaining / 60);
    const seconds = this.timeRemaining % 60;

    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");
    return `${formattedMinutes}:${formattedSeconds}`;
  }

  copyQRCode() {
    const input = this.renderer.createElement("input");
    input.value = this.qrCodePayload;
    this.renderer.appendChild(document.body, input);
    input.select();
    document.execCommand("copy");
    this.renderer.removeChild(document.body, input);

    this.qrCodeClipped = true;
    this.qrCodeClipHandler();
  }

  qrCodeClipHandler() {
    setTimeout(() => {
      this.qrCodeClipped = false;
    }, 2000);
  }

}
