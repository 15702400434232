<section class="container-fluid p-4" style="background-color: #070813 !important;">
    <div *ngIf="step1">
        <div class="d-flex justify-content-end">
            <i class="bi bi-x-lg text-white cursor-pointer" (click)="exit()"></i>
        </div>
        <div class="d-flex justify-content-center align-items-center">
            <img src="../../../../../assets/img/logo-white.svg" alt="">
        </div>
        <div class="d-flex justify-content-between align-items-center mt-4">
            <div class="d-flex flex-column align-items-center center justify-content-center">
                <i class="bi bi-exclamation-circle-fill text-warning"></i>
                <p class="text-white text-center" style="font-size: 14px;width: 75px;">Escolher
                    Produto</p>
            </div>
            <div class="line-fake-custom"></div>
            <div class="d-flex flex-column align-items-center center justify-content-center">
                <i class="bi bi-circle text-white"></i>
                <p class="text-white text-center" style="font-size: 14px;width: 75px;">Dados
                    Pessoais</p>
            </div>
            <div class="line-fake-custom"></div>
            <div class="d-flex flex-column align-items-center center justify-content-center">
                <i class="bi bi-circle text-white"></i>
                <p class="text-white text-center" style="font-size: 14px;width: 75px;">Dados de
                    Pagamento</p>
            </div>
            <div class="line-fake-custom"></div>
            <div class="d-flex flex-column align-items-center center justify-content-center">
                <i class="bi bi-circle text-white"></i>
                <p class="text-white text-center ms-sm-2" style="font-size: 14px;height: 50px;">Confirmação</p>
            </div>
        </div>
        <hr class="mt-4">
        <div class="mt-4 selected-card-chance">
            <div class="d-flex flex-sm-row flex-column justify-content-between align-items-center p-4">
                <div class="d-flex">
                    <img src="../../../../../assets/img/logo-ticket.png" class="img-fluid"
                        style="max-width: 50px;max-height: 50px;">
                    <div class="d-flex flex-column ms-3 justify-content-center">
                        <h5 class="fw-bold text-white">{{response.chances.numberOfChances}} TÍTULOS</h5>
                    </div>
                </div>
                <div class="d-flex flex-column justify-content-center"
                    *ngIf="response.chances.value > response.chances.valueWithDiscount">
                    <p class="text-danger fw-bold"><span style="text-decoration: line-through;">de
                            {{response.chances.value |
                            currency: 'R$ '}} </span>por</p>
                    <h6 class="fw-bold text-white">{{response.chances.valueWithDiscount |
                        currency: 'R$ '}}</h6>
                </div>
                <div class="d-flex flex-column justify-content-center"
                    *ngIf="response.chances.value <= response.chances.valueWithDiscount">
                    <h6 class="fw-bold text-white">{{response.chances.value |
                        currency: 'R$ '}}</h6>
                </div>
            </div>
        </div>
        <div class="rounded-3 mt-4 p-4" style="background-color: #2B2B2B !important;">
            <p class="mt-3 text-white fw-bold text-center">Números para participação</p>
            <div id="carouselExample" class="carousel slide mt-4" data-bs-ride="carousel">
                <div class="carousel-inner">
                    <div *ngIf="!numbersResponseLoading">
                        <div class="carousel-item" *ngFor="let item of arrNumbers; let isFirst = first;let i = index"
                            [class.active]="isFirst">
                            <div class="d-flex justify-content-between align-items-center mb-4">
                                <p class="mb-0 text-white">Número do título: {{item.cardNumber}}</p>
                                <button class="btn btn-flag-title">Título:&nbsp;{{i + 1}}</button>
                            </div>
                            <div class="d-flex flex-wrap justify-content-end align-items-center">
                                <div *ngFor="let content of item.numbers" class="ball-number-custom">{{content}}</div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="numbersResponseLoading" class="d-flex justify-content-center align-items-center">
                        <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExample"
                    data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExample"
                    data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>
        </div>
        <div class="d-flex flex-column justify-content-center mt-4">
            <button class="btn btn-genereate-new" (click)="regenerateNumbers()"><i
                    class="bi bi-arrow-clockwise me-3 text-white"></i>Gerar Novos
                Títulos</button>
            <button class="btn btn-continue-custom mt-4" (click)="next(2)">Continuar</button>
        </div>
    </div>
    <div *ngIf="step2">
        <div class="d-flex justify-content-between">
            <i class="bi bi-chevron-left cursor-pointer text-white" (click)="next(1)"></i>
            <i class="bi bi-x-lg text-white cursor-pointer" (click)="exit()"></i>
        </div>
        <div class="d-flex justify-content-center align-items-center">
            <img src="../../../../../assets/img/logo-white.svg" alt="">
        </div>
        <div class="d-flex justify-content-between align-items-center mt-4">
            <div class="d-flex flex-column align-items-center center justify-content-center">
                <i class="bi bi-check-circle-fill" style="color: #00BE04 !important;"></i>
                <p class="text-white text-center" style="font-size: 14px;width: 75px;">Escolher
                    Produto</p>
            </div>
            <div class="line-fake-custom"></div>
            <div class="d-flex flex-column align-items-center center justify-content-center">
                <i class="bi bi-exclamation-circle-fill text-warning"></i>
                <p class="text-white text-center" style="font-size: 14px;width: 75px;">Dados
                    Pessoais</p>
            </div>
            <div class="line-fake-custom"></div>
            <div class="d-flex flex-column align-items-center center justify-content-center">
                <i class="bi bi-circle text-white"></i>
                <p class="text-white text-center" style="font-size: 14px;width: 75px;">Dados de
                    Pagamento</p>
            </div>
            <div class="line-fake-custom"></div>
            <div class="d-flex flex-column align-items-center center justify-content-center">
                <i class="bi bi-circle text-white"></i>
                <p class="text-white text-center ms-sm-2" style="font-size: 14px;height: 50px;">Confirmação</p>
            </div>
        </div>
        <hr class="mt-4">
        <div class="mt-4 selected-card-chance">
            <div class="d-flex flex-sm-row flex-column justify-content-between align-items-center p-4">
                <div class="d-flex">
                    <img src="../../../../../assets/img/logo-ticket.png" class="img-fluid"
                        style="max-width: 50px;max-height: 50px;">
                    <div class="d-flex flex-column ms-3 justify-content-center">
                        <h5 class="fw-bold text-white">{{response.chances.numberOfChances}} TÍTULOS</h5>
                    </div>
                </div>
                <div class="d-flex flex-column justify-content-center"
                    *ngIf="response.chances.value > response.chances.valueWithDiscount">
                    <p class="text-danger fw-bold"><span style="text-decoration: line-through;">de
                            {{response.chances.value |
                            currency: 'R$ '}} </span>por</p>
                    <h6 class="fw-bold text-white">{{response.chances.valueWithDiscount |
                        currency: 'R$ '}}</h6>
                </div>
                <div class="d-flex flex-column justify-content-center"
                    *ngIf="response.chances.value <= response.chances.valueWithDiscount">
                    <h6 class="fw-bold text-white">{{response.chances.value |
                        currency: 'R$ '}}</h6>
                </div>
            </div>
        </div>

        <div class="mt-4">
            <p class="text-white">Preencha o formulário abaixo:</p>
            <p class="text-white my-4">Informações pessoais</p>
            <form [formGroup]="formUserData">
                <div class="row">
                    <div class="col-12 mt-3">
                        <label for="" class="text-white">Nome completo</label>
                        <input type="text" class="form-control rounded-3 bg-transparent text-white"
                            formControlName="name">
                        <div *ngIf="formUserData.controls['name'].dirty && formUserData.controls['name'].errors"
                            class="div-msg-error mt-2">
                            <img src="../../../assets/icons/icon-alert.svg" class="img-msg-error"
                                alt="icone de atencao">
                            <p class="fs-14 text-white">Nome Completo é obrigatório.</p>
                        </div>
                    </div>
                    <div class="col-sm-6 mt-3">
                        <label for="" class="text-white">CPF</label>
                        <input type="text" class="form-control rounded-3 bg-transparent text-white"
                            formControlName="cpf" mask="000.000.000-00">
                        <div *ngIf="formUserData.controls['cpf'].dirty && (formUserData.controls['cpf'].errors || !validarCPF(formUserData.controls['cpf'].value))"
                            class="div-msg-error mt-2">
                            <img src="../../../assets/icons/icon-alert.svg" class="img-msg-error"
                                alt="icone de atencao">
                            <p class="fs-14 text-white">CPF inválido</p>
                        </div>
                    </div>
                    <div class="col-sm-6 mt-3">
                        <label for="" class="text-white">E-mail</label>
                        <input type="email" class="form-control rounded-3 bg-transparent text-white"
                            formControlName="email">
                        <div *ngIf="formUserData.controls['email'].dirty && formUserData.controls['email'].errors"
                            class="div-msg-error mt-2">
                            <img src="../../../assets/icons/icon-alert.svg" class="img-msg-error"
                                alt="icone de atencao">
                            <p class="fs-14 text-white">Email inválido</p>
                        </div>
                    </div>
                    <div class="col-sm-6 mt-3">
                        <label for="" class="text-white">Celular</label>
                        <input type="text" class="form-control rounded-3 bg-transparent text-white"
                            formControlName="phone" mask="00 00000-0000">
                        <div *ngIf="formUserData.controls['phone'].errors?.['minlength']" class="div-msg-error mt-2">
                            <img src="../../../assets/icons/icon-alert.svg" class="img-msg-error"
                                alt="icone de atencao">
                            <p class="fs-14 text-white">Número inválido</p>
                        </div>
                    </div>
                    <div class="col-sm-6 mt-3">
                        <label for="" class="text-white">Data de nascimento</label>
                        <input type="date" class="form-control rounded-3 bg-transparent text-white"
                            formControlName="date">
                        <div *ngIf="formUserData.controls['date'].dirty && formUserData.controls['date'].errors"
                            class="div-msg-error mt-2">
                            <img src="../../../assets/icons/icon-alert.svg" class="img-msg-error"
                                alt="icone de atencao">
                            <p class="fs-14 text-white">Data de nascimento inválida</p>
                        </div>
                        <div *ngIf="formUserData.get('date').hasError('invalidAge')" class="div-msg-error mt-2">
                            <img src="../../../assets/icons/icon-alert.svg" class="img-msg-error"
                                alt="icone de atencao">
                            <p class="fs-14 text-white">A idade deve ser maior ou igual a 16 anos.</p>
                        </div>
                    </div>
                    <div class="col-12 my-4">
                        <label for="" class="text-white">Endereço</label>
                    </div>
                    <div class="col-12 mt-3">
                        <label for="" class="text-white">CEP</label>
                        <input type="text" class="form-control rounded-3 bg-transparent text-white"
                            formControlName="cep" mask="00000-000">
                        <div *ngIf="formUserData.controls['cep'].dirty && formUserData.controls['cep'].errors"
                            class="div-msg-error mt-2">
                            <p class="fs-14 text-white">CEP inválido ou não encontrado.</p>
                        </div>
                    </div>
                    <div class="col-sm-6 mt-3">
                        <label for="" class="text-white">Cidade</label>
                        <input type="text" class="form-control rounded-3 bg-transparent text-white" readonly
                            formControlName="city">
                    </div>
                    <div class="col-sm-6 mt-3">
                        <label for="" class="text-white">Estado</label>
                        <input type="text" class="form-control rounded-3 bg-transparent text-white" readonly
                            formControlName="uf">
                    </div>
                    <div class="col-12 d-flex justify-content-center">
                        <button class="btn btn-continue-custom mt-4" [disabled]="formUserData.invalid"
                            (click)="next(3)">Continuar</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div *ngIf="step3">
        <div class="d-flex justify-content-between">
            <i class="bi bi-chevron-left cursor-pointer text-white" (click)="next(1)"></i>
            <i class="bi bi-x-lg text-white cursor-pointer" (click)="exit()"></i>
        </div>
        <div class="d-flex justify-content-center align-items-center">
            <img src="../../../../../assets/img/logo-white.svg" alt="">
        </div>
        <div class="d-flex justify-content-between align-items-center mt-4">
            <div class="d-flex flex-column align-items-center center justify-content-center">
                <i class="bi bi-check-circle-fill" style="color: #00BE04 !important;"></i>
                <p class="text-white text-center" style="font-size: 14px;width: 75px;">Escolher
                    Produto</p>
            </div>
            <div class="line-fake-custom"></div>
            <div class="d-flex flex-column align-items-center center justify-content-center">
                <i class="bi bi-check-circle-fill" style="color: #00BE04 !important;"></i>
                <p class="text-white text-center" style="font-size: 14px;width: 75px;">Dados
                    Pessoais</p>
            </div>
            <div class="line-fake-custom"></div>
            <div class="d-flex flex-column align-items-center center justify-content-center">
                <i class="bi bi-exclamation-circle-fill text-warning"></i>
                <p class="text-white text-center" style="font-size: 14px;width: 75px;">Dados de
                    Pagamento</p>
            </div>
            <div class="line-fake-custom"></div>
            <div class="d-flex flex-column align-items-center center justify-content-center">
                <i class="bi bi-circle text-white"></i>
                <p class="text-white text-center ms-sm-2" style="font-size: 14px;height: 50px;">Confirmação</p>
            </div>
        </div>
        <hr class="mt-4">
        <div class="mt-4 selected-card-chance">
            <div class="d-flex flex-sm-row flex-column justify-content-between align-items-center p-4">
                <div class="d-flex">
                    <img src="../../../../../assets/img/logo-ticket.png" class="img-fluid"
                        style="max-width: 50px;max-height: 50px;">
                    <div class="d-flex flex-column ms-3 justify-content-center">
                        <h5 class="fw-bold text-white">{{response.chances.numberOfChances}} TÍTULOS</h5>
                    </div>
                </div>
                <div class="d-flex flex-column justify-content-center"
                    *ngIf="response.chances.value > response.chances.valueWithDiscount">
                    <p class="text-danger fw-bold"><span style="text-decoration: line-through;">de
                            {{response.chances.value |
                            currency: 'R$ '}} </span>por</p>
                    <h6 class="fw-bold text-white">{{response.chances.valueWithDiscount |
                        currency: 'R$ '}}</h6>
                </div>
                <div class="d-flex flex-column justify-content-center"
                    *ngIf="response.chances.value <= response.chances.valueWithDiscount">
                    <h6 class="fw-bold text-white">{{response.chances.value |
                        currency: 'R$ '}}</h6>
                </div>
            </div>
        </div>
        <div class="rounded-3 mt-4 p-4" style="background-color: #2B2B2B !important;">
            <div class="d-flex justify-content-between align-content-center">
                <p class="fw-bold text-white">Comprar com pix</p>
                <img src="../../../../../assets/img/pix-logo.png" alt="">
            </div>
            <p class="text-white mt-3"><i class="bi bi-clock me-3"></i> Aprovação imediata</p>
            <p class="text-white"><i class="bi bi-shield-check me-3"></i> Transação segura</p>
            <p class="text-white"><i class="bi bi-qr-code me-3"></i> Compre com facilidade</p>

            <p class="text-white">Pagar no pix é o jeito mais fácil e rápido para você contribuir e concorrer.</p>
        </div>

        <div class="container mb-2 mt-5">
            <div class="card mb-4" style="background-color: #2B2B2B !important;">
                <div class="card-header d-flex justify-content-between align-items-center">
                    <div class="d-flex">
                        <i class="bi bi-file-earmark-check text-white"></i>
                        <p class="text-white ps-2">{{ cardConditions.title }}</p>
                    </div>
                    <button class="btn" (click)="toggleCard()">
                        <i class="bi bi-caret-up text-white"
                            [ngClass]="{'rotate': isCardExpanded, 'rotate-up': isCardExpanded}"></i>
                    </button>
                </div>
                <div [class.collapse]="!isCardExpanded" [class.show]="isCardExpanded">
                    <div class="px-3 justify-text py-2" style="background-color: #FFC048 !important;">
                        <div class="d-flex align-items-center">
                            <input type="checkbox" class="form-check" value="true" checked>
                            <span class="ms-3"> Aceito os termos abaixo!</span>
                        </div>
                        <hr>
                        <p class="fs-14">{{ cardConditions.content }}</p>
                    </div>
                </div>
            </div>
        </div>
        <button class="btn btn-continue-custom mt-4" (click)="next(4)">Continuar</button>
    </div>
    <div *ngIf="step4">
        <div class="d-flex justify-content-between">
            <i class="bi bi-chevron-left cursor-pointer text-white" (click)="next(1)"></i>
            <i class="bi bi-x-lg text-white cursor-pointer" (click)="exit()"></i>
        </div>
        <div class="d-flex justify-content-center align-items-center">
            <img src="../../../../../assets/img/logo-white.svg" alt="">
        </div>
        <div class="d-flex justify-content-between align-items-center mt-4">
            <div class="d-flex flex-column align-items-center center justify-content-center">
                <i class="bi bi-check-circle-fill" style="color: #00BE04 !important;"></i>
                <p class="text-white text-center" style="font-size: 14px;width: 75px;">Escolher
                    Produto</p>
            </div>
            <div class="line-fake-custom"></div>
            <div class="d-flex flex-column align-items-center center justify-content-center">
                <i class="bi bi-check-circle-fill" style="color: #00BE04 !important;"></i>
                <p class="text-white text-center" style="font-size: 14px;width: 75px;">Dados
                    Pessoais</p>
            </div>
            <div class="line-fake-custom"></div>
            <div class="d-flex flex-column align-items-center center justify-content-center">
                <i class="bi bi-exclamation-circle-fill text-warning"></i>
                <p class="text-white text-center" style="font-size: 14px;width: 75px;">Dados de
                    Pagamento</p>
            </div>
            <div class="line-fake-custom"></div>
            <div class="d-flex flex-column align-items-center center justify-content-center">
                <i class="bi bi-circle text-white"></i>
                <p class="text-white text-center ms-sm-2" style="font-size: 14px;height: 50px;">Confirmação</p>
            </div>
        </div>
        <hr class="mt-4">
        <div class="await-payment-section">
            <p class="mb-0 text-black text-center"><i class="bi bi-clock me-3"></i>Aguardando seu pagamento</p>
        </div>
        <div class="d-flex flex-column p-4"
            style="background-color: #2B2B2B !important; border-radius: 0px 0px 10px 10px;">
            <p class="text-white text-center mt-3">Um código <b>Pix Copia e Cola</b> foi gerado, para efetivar seu
                pedido
                siga as
                instruções:</p>
            <div class="d-flex justify-content-center align-items-center mt-4">
                <div class="spinner-border" role="status" *ngIf="qrCodeLoading">
                    <span class="visually-hidden">Loading...</span>
                </div>
                <img *ngIf="!qrCodeLoading" class="img-fluid" [src]="'data:image/png;base64,' + qrCodeImage"
                    alt="QR Code">
            </div>
            <p class="text-white mt-5" style="font-size: 14px;">1. Clique no botão <b>“Copiar Código Pix”</b> ou Leia o
                QrCode.</p>
            <p class="text-white" style="font-size: 14px;">2. Abra seu aplicativo do banco e acesse <b>“Pix”</b>.</p>
            <p class="text-white" style="font-size: 14px;">3. Selecione a opção pagar com código <b>Pix Copia e Cola</b>
                e cole o código.</p>
            <p class="text-white" style="font-size: 14px;">4. Após a confirmação do pagamento retorne para esta tela e
                <b>clique no botão
                    "Confirmar Pagamento".</b>
            </p>
        </div>
        <div class="d-flex justify-content-center align-items-center mt-4">
            <button class="btn btn-time-custom d-flex align-items-center">Tempo restante: <app-timer
                    [stageSelect]="timerExpired" class="ms-2"></app-timer></button>
        </div>
        <button class="btn btn-continue-custom mt-4" (click)="copyQRCode()"><i class="bi bi-files me-3"></i>
            <span *ngIf="!qrCodeClipped">Copiar e colar</span>
            <span *ngIf="qrCodeClipped">Copiado</span>
        </button>
    </div>
    <div *ngIf="step5">
        <div class="d-flex justify-content-between">
            <i class="bi bi-chevron-left cursor-pointer text-white" (click)="next(1)"></i>
            <i class="bi bi-x-lg text-white cursor-pointer" (click)="exit()"></i>
        </div>
        <div class="d-flex justify-content-center align-items-center">
            <img src="../../../../../assets/img/logo-white.svg" alt="">
        </div>
        <div class="d-flex justify-content-between align-items-center mt-4">
            <div class="d-flex flex-column align-items-center center justify-content-center">
                <i class="bi bi-check-circle-fill" style="color: #00BE04 !important;"></i>
                <p class="text-white text-center" style="font-size: 14px;width: 75px;">Escolher
                    Produto</p>
            </div>
            <div class="line-fake-custom"></div>
            <div class="d-flex flex-column align-items-center center justify-content-center">
                <i class="bi bi-check-circle-fill" style="color: #00BE04 !important;"></i>
                <p class="text-white text-center" style="font-size: 14px;width: 75px;">Dados
                    Pessoais</p>
            </div>
            <div class="line-fake-custom"></div>
            <div class="d-flex flex-column align-items-center center justify-content-center">
                <i class="bi bi-exclamation-circle-fill text-warning"></i>
                <p class="text-white text-center" style="font-size: 14px;width: 75px;">Dados de
                    Pagamento</p>
            </div>
            <div class="line-fake-custom"></div>
            <div class="d-flex flex-column align-items-center center justify-content-center">
                <i class="bi bi-circle text-white"></i>
                <p class="text-white text-center ms-sm-2" style="font-size: 14px;height: 50px;">Confirmação</p>
            </div>
        </div>
        <hr class="mt-4">
        <div class="d-flex flex-column justify-content-center align-items-center">
            <img src="../../../../../assets/img/confirmation-success-icon.png">
            <p class="text-center text-white mt-4">Caso tenha efetuado o pagamento corretamente</p>
            <p class="text-center text-white fw-bold">Entraremos em contato por e-mail em breve.</p>
            <p class="text-white mt-5">Confira seu <b>e-mail</b> com seu(s) título(s)</p>
        </div>
    </div>
</section>