import { Component, ElementRef, Renderer2 } from '@angular/core';
import { CampaignService } from 'src/services/campaign.service';

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss']
})
export class ResultsComponent {

  response: any = [];

  constructor(
    private campaignService: CampaignService,
    private el: ElementRef,
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
    this.campaignService.getFinished().subscribe({
      next: data => {
        this.response = data;
        let dataResponse: any = data;
        let content: any = [];

        for (let i = 0; i < this.response.length; i++) {
          if (this.response[i].campaign) {
            content.push(this.response[i].campaign)
          }
        }
        content = this.removerObjetosRepetidosPorId(content);

        for (let i = 0; i < content.length; i++) {
          for (let x = 0; x < content[i].stages.length; x++) {
            const ganhadores = [];
            for (let j = 0; j < dataResponse.length; j++) {
              if (dataResponse[j].stageId === content[i].stages[x].id) {
                Object.assign(dataResponse[j].sale, { drawNumber: dataResponse[j].drawNumber, prize: dataResponse[j].prize })
                ganhadores.push(dataResponse[j].sale);
              }
            }
            content[i].stages[x].win = ganhadores;
          }
        }

        this.response = this.orderPerDate(content);
      }
    })
  }

  orderPerDate(array: any[]): any[] {
    return array.sort((a, b) => {
      const dataA = new Date(a.publishDate).getTime();
      const dataB = new Date(b.publishDate).getTime();
      return dataB - dataA;
    });
  }

  removerObjetosRepetidosPorId(arrayDeObjetos) {
    const idsVistos = new Set();
    const arrayUnico = [];

    for (const objeto of arrayDeObjetos) {
      if (!idsVistos.has(objeto._id)) {
        idsVistos.add(objeto._id);
        arrayUnico.push(objeto);
      }
    }

    return arrayUnico;
  }

  ngAfterViewInit() {
    this.resizeImageContainer();
    window.addEventListener('resize', () => this.resizeImageContainer());
  }

  onImageLoad() {
    this.resizeImageContainer();
  }

  resizeImageContainer() {
    const container = this.el.nativeElement.querySelector('.content-style-custom');
    const image = this.el.nativeElement.querySelector('.bg-faq-custom');

    if (container && image) {
      this.renderer.setStyle(container, 'height', `${image.clientHeight}px`);
    }
  }

}
