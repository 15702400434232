import { HttpClient } from "@angular/common/http";
import { Component, ElementRef, HostListener, QueryList, Renderer2, ViewChildren } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { SaleRegisterBuyerDataRequestDto } from "src/dtos/sale-register-buyer-data-request.dto";
import { CampaignService } from "src/services/campaign.service";
import { PaymentService } from "src/services/payment.service";
import { SaleService } from "src/services/sale.service";
import { MessageSocketService } from "../../../../services/socket/message.socket.service";
import { Chances } from "src/dtos/campaign/campaign.interface";
import { formatDate } from "@angular/common";

@Component({
  selector: "app-checkout",
  templateUrl: "./checkout.component.html",
  styleUrls: ["./checkout.component.scss"],
})
export class CheckoutComponent {
  formPersonalData!: FormGroup;
  formAddress!: FormGroup;

  stepOne: boolean = true;
  stepTwo: boolean = false;
  stepThree: boolean = false;
  stepFour: boolean = false;

  stepOnePix: boolean = true;
  stepTwoPix: boolean = false;

  isSubmit: boolean = false;

  personalData!: SaleRegisterBuyerDataRequestDto;

  qrCodeImage: string = "";
  qrCodePayload: string = "";

  qrCodeClipped: boolean = false;

  cardImageWidth: number = 70;

  cards: any[] = [];
  selectedCampaignId: string = "";

  selectedCard: Chances | null = null;

  arrowImage = "../../../assets/icons/seta-icon.svg";

  textWarning: string = 'Título de Capitalização da Modalidade Filantropia Premiável de Contribuição Única emitido pela VIA CAPITALIZAÇÃO S.A. (VIACAP), CNPJ 88.076.302/0001-94, aprovado pelo Processo SUSEP nº 15414.623406/2023-75. SAC 0800 740 7819. OUVIDORIA 0800 703 1989, de segunda a sexta-feira, das 8h às 17h. É proibida a venda de título de capitalização a menores de 16 anos. O valor não exigido dentro do prazo prescricional, estabelecido pela legislação em vigor, acarretará a perda desse direito. A aquisição deste título faculta ao adquirente a cessão de 100% do direito de resgate à A ABESS, certificada nos termos da legislação em vigor. Antes de contratar consulte previamente as Condições Gerais. As condições contratuais/regulamento deste produto protocolizadas pela sociedade junto à SUSEP poderão ser consultadas no endereço eletrônico www.susep.gov.br, de acordo com o número de processo constante da proposta. Prêmios líquidos de imposto de renda. Confira o resultado dos sorteios e as condições de participação em www.mundodasorteoficial.com.br. Imagens meramente ilustrativas.';

  cardConditions = {
    title: "Condições e Termos de Uso",
    content:
      "Na qualidade de Subscritor deste titulo de capitalização de Contribuição da Modalidade Filantropia Premiável, emitido pela VIA CAPITALIZAÇÃO S.A. (VIACAP), declaro que: I. Recebi li e entendi as Condições Gerais deste titulo de capitalização de Contribuição onde concordo com os seus termos e autorizo sem ônus, a utilização de meu nome, voz e imagem para divulgação desta campanha; II. Tenho conhecimento de que a contratação da presente proposta de subscrição implica na automática adesão a todos os termos das condições Gerais do titulo de capitalização de Contribuição e que este documenta encontra-se ao meu dispor no site: www.mundodasorteoficial.com.br."
  };

  isCardExpanded: boolean = false;

  timeRemaining: number = 10 * 60;
  intervalId: any;

  selectedCampaign: any;

  timer: any;
  remainingTime: number = 10 * 60;
  timerDisplay: { minutes: string; seconds: string; days?: string; hours?: string } = { minutes: "10", seconds: "00" };
  promotionEnded: boolean = false;

  stageSelect: any;

  outhersCampaign: any = [];

  @ViewChildren('elementoArray') elementosArray: QueryList<ElementRef>;
  itemComMaiorQuantidade = null;
  elementoSelecionado: ElementRef | null = null;

  countErrorView: number = 0;
  timerExpired: any = {
    saleDeadline: new Date()
  }

  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private toastrService: ToastrService,
    private elementRef: ElementRef,
    private spinner: NgxSpinnerService,
    private readonly router: Router,
    private paymentService: PaymentService,
    private saleService: SaleService,
    private campaignService: CampaignService,
    private renderer: Renderer2,
    private messageSocketService: MessageSocketService,
    private activatedRoute: ActivatedRoute
  ) {
    const regex = new RegExp(/^[a-zA-ZÀ-Ÿ][A-zÀ-ÿ']+\s([a-zA-zÀ-ÿ']\s?)*[a-zA-ZÀ-Ÿ][a-zA-zÀ-ÿ']+$/);

    this.formPersonalData = this.formBuilder.group({
      name: ["", [Validators.required, Validators.maxLength(50), Validators.pattern(regex), Validators.minLength(5)]],
      cpf: ["", [Validators.required, Validators.minLength(11)]],
      email: [
        "",
        [Validators.required, Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")],
      ],
      phone: ["", [Validators.required, Validators.minLength(11)]],
      date: ["", [Validators.required, Validators.minLength(8), this.validateAge]],
    });

    this.formAddress = this.formBuilder.group({
      cep: ["", [Validators.required]],
      city: ["", [Validators.required]],
      uf: ["", [Validators.required]],
    });
  }

  validateAge(control: any): { [key: string]: any } | null {
    if (!control.value) return null;
    const birthDate = new Date(control.value);

    const currentDate = new Date();
    let difference = currentDate.getTime() - birthDate.getTime();
    let age = Math.floor(difference / 31557600000);

    if (age < 16) {
      return { invalidAge: true };
    }
    return null;
  }

  @HostListener("window:resize")
  onWindowResize() {
    this.checkScreenSize();
  }

  checkScreenSize() {
    if (window.innerWidth < 399) {
      this.cardImageWidth = 42;
    } else {
      this.cardImageWidth = 70;
    }
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.campaignService.getPublished().subscribe({
        next: data => {
          this.selectedCampaign = data;
          this.selectedCampaignId = data._id;
          for (let iterator of data.stages) {
            if (iterator.id === params["id"]) {
              this.stageSelect = iterator;
            }
          }

          var dataOriginal = new Date(this.stageSelect.saleDeadline);
          dataOriginal.setHours(dataOriginal.getHours() + 3);

          if (new Date(dataOriginal) <= new Date()) {
            this.outhersCampaign = this.selectedCampaign.stages.filter(el => el.id !== this.stageSelect.id && new Date(el.saleDeadline) > new Date())
            this.promotionEnded = true;
            return;
          }
          const startDate = new Date().getTime();
          const endDate = new Date(this.stageSelect.saleDeadline).getTime();
          this.remainingTime = Math.abs(endDate - startDate);
          this.startTimer();
          this.greaterChance();
          this.sortArrayByNumberOfChances();
        },
        error: error => {
          console.error(error);
        },
      });
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.selectChance();
    }, 1000);
  }

  selectChance() {
    this.elementosArray.forEach(element => {
      element.nativeElement.addEventListener('click', () => {
        if (this.elementoSelecionado) {
          this.elementoSelecionado.nativeElement.classList.remove('selected-card-chance');
        }
        element.nativeElement.classList.add('selected-card-chance');
        this.elementoSelecionado = element;
      });
    });
  }

  greaterChance() {
    this.itemComMaiorQuantidade = this.stageSelect.chances.reduce((prev, current) => {
      return (prev.numberOfChances > current.numberOfChances) ? prev : current;
    });
  }

  sortArrayByNumberOfChances() {
    this.stageSelect.chances = this.stageSelect.chances.sort((a, b) => {
      return b.numberOfChances - a.numberOfChances;
    });
  }

  updateTimer() {
    const days = Math.floor(this.remainingTime / (1000 * 60 * 60 * 24));
    const hours = Math.floor((this.remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((this.remainingTime % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((this.remainingTime % (1000 * 60)) / 1000);

    const formattedDays = days ? `${days}` : "";
    const formattedHours = hours ? `${hours}` : "";
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes.toString();
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds.toString();

    this.timerDisplay = {
      minutes: formattedMinutes,
      seconds: formattedSeconds,
      days: formattedDays,
      hours: formattedHours,
    };

    this.remainingTime = this.remainingTime - 1000;

    if (this.remainingTime < 0) {
      this.stopTimer();
      this.promotionEnded = true;
      this.updateCardValues();
    }
  }

  startTimer() {
    this.timer = setInterval(() => {
      this.updateTimer();
    }, 1000);
  }

  stopTimer() {
    clearInterval(this.timer);
  }

  updateCardValues() {
    for (const card of this.cards) {
      card.trueValue = card.value;
      card.value = this.promotionEnded ? card.fictitiousValue : card.trueValue;
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId);
  }

  generatePixQrCode() {
    if (!this.personalData || !this.selectedCard || !this.selectedCard.value || Number(this.selectedCard.value) <= 0) {
      this.toastrService.error("Por favor, selecione uma campanha válida e preencha todas as informações necessárias.");
      this.spinner.hide();
      this.backToHome();
      return;
    }

    const dto = {
      stageId: this.stageSelect.id,
      campaignId: this.selectedCampaignId,
      buyerData: this.personalData,
      chancesNumbers: this.selectedCard.numberOfChances,
    };

    this.saleService.salePixCheckout(dto).subscribe({
      next: (data: any) => {
        this.toastrService.success("Pix gerado com sucesso!");
        this.qrCodeImage = (data as { data: { pixQrCode: string } }).data.pixQrCode;
        this.qrCodePayload = (data as { data: { pixPayload: string } }).data.pixPayload;

        localStorage.setItem("saleId", data.data._id);

        this.messageSocketService.requestSaleConfirmationResponse();

        this.messageSocketService.saleIds.subscribe({
          next: (response: string) => {
            const saleIdLocal = localStorage.getItem("saleId");
            if (response === saleIdLocal) {
              localStorage.removeItem("saleId");
              this.confirmPayment();
            }
          },
        });

        this.spinner.hide();
      },
      error: error => {
        console.error(error);
        this.toastrService.error("Não foi possível gerar o QR Code, tente novamente mais tarde!");
        this.spinner.hide();
      },
    });
  }

  copyQRCode() {
    const input = this.renderer.createElement("input");
    input.value = this.qrCodePayload;
    this.renderer.appendChild(document.body, input);
    input.select();
    document.execCommand("copy");
    this.renderer.removeChild(document.body, input);

    this.qrCodeClipped = true;
    this.qrCodeClipHandler();
  }

  qrCodeClipHandler() {
    setTimeout(() => {
      this.qrCodeClipped = false;
    }, 1300);
  }

  formatarTempoRestante(): string {
    const minutes = Math.floor(this.timeRemaining / 60);
    const seconds = this.timeRemaining % 60;

    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");

    return `${formattedMinutes}:${formattedSeconds}`;
  }

  startCounter() {
    this.intervalId = setInterval(() => {
      if (this.timeRemaining <= 0) {
        clearInterval(this.intervalId);
        this.timeRemaining = 0;

        const contadorElemento = document.getElementById("contador");
        if (contadorElemento) {
          contadorElemento.textContent = "Tempo acabou!";
        }

        this.countErrorView += 1;
        if (this.countErrorView === 1) {
          this.toastrService.error("O tempo para o pagamento do pix expirou!");
          this.router.navigate(["/"]);
        }
      } else {
        this.timeRemaining--;
      }
    }, 1000);
  }

  saveFormDataToLocalStorage() {
    localStorage.setItem("personalData", JSON.stringify(this.formPersonalData.value));
    localStorage.setItem("address", JSON.stringify(this.formAddress.value));
  }

  loadFormDataFromLocalStorage() {
    const personalDataString = localStorage.getItem("personalData");
    const addressString = localStorage.getItem("address");

    if (personalDataString) {
      const personalData = JSON.parse(personalDataString);
      this.formPersonalData.setValue(personalData);
    }

    if (addressString) {
      const address = JSON.parse(addressString);
      this.formAddress.setValue(address);
    }
  }

  submitForm(): void {
    this.isSubmit = true;

    const dto: SaleRegisterBuyerDataRequestDto = {
      name: this.formPersonalData.controls["name"].value,
      email: this.formPersonalData.controls["email"].value,
      cpf: this.formPersonalData.controls["cpf"].value,
      phone: this.formPersonalData.controls["phone"].value,
      dateOfBirth: this.formPersonalData.controls["date"].value,
      address: {
        zipCode: this.formAddress.controls["cep"].value,
        city: this.formAddress.controls["city"].value,
        state: this.formAddress.controls["uf"].value,
      },
    };

    this.personalData = dto;

    if (this.formPersonalData.invalid || this.formAddress.invalid) {
      this.toastrService.error("Preenchimento do formulário é obrigatório!");
      return;
    }

    this.toastrService.success("Informações salvas com sucesso!");
    this.saveFormDataToLocalStorage();
    this.stepTwo = false;
    this.stepThree = true;
    this.timerExpired.saleDeadline = this.add10minutes();
  }

  add10minutes() {
    const dataAtual = new Date();
    const novaData = new Date(dataAtual.getTime() + 10 * 60000);

    novaData.setHours(novaData.getHours() - 3);

    const dataFormatada = novaData.toISOString();

    return dataFormatada;
  }

  generateQrCode() {
    this.spinner.show();

    setTimeout(() => {
      this.stepOnePix = false;
      this.startCounter();
      this.generatePixQrCode();
      this.stepTwoPix = true;
    }, 2000);
  }

  confirmPayment() {
    this.spinner.show();

    setTimeout(() => {
      this.stepOnePix = true;
      this.stepTwoPix = false;
      this.stepOne = false;
      this.stepTwo = false;
      this.stepThree = false;
      this.stepFour = true;

      this.spinner.hide();
    }, 2000);
  }

  toggleCard() {
    this.isCardExpanded = !this.isCardExpanded;
  }

  chanceSelect(chance: any) {
    // let stageSelectWithChanceTemp = JSON.parse(JSON.stringify(this.stageSelect));
    // for (let iterator of this.stageSelect.chances) {
    //   if (iterator.numberOfChances !== chance.numberOfChances) {
    //     stageSelectWithChanceTemp.chances = undefined;
    //     stageSelectWithChanceTemp.chances = chance;
    //     Object.assign(stageSelectWithChanceTemp, { campaingId: this.selectedCampaign._id })
    //   }
    // }
    this.selectedCard = chance;
  }

  fetchAddressByCep(cep: string) {
    if (cep && cep.length === 8) {
      this.spinner.show();
      this.http.get(`https://viacep.com.br/ws/${cep}/json/`).subscribe((response: any) => {
        if (!response.erro) {
          this.formAddress.patchValue({
            city: response.localidade,
            uf: response.uf,
          });

          this.spinner.hide();
        } else {
          this.formAddress.get("cep")?.setErrors({ cepInvalid: true });
          this.spinner.hide();
        }
      });
    }
  }

  // selectCard(index: number) {
  //   for (let i = 0; i < this.stageSelect.chances.length; i++) {
  //     if (i === index) {
  //       this.selectedCard = this.stageSelect.chances[i];
  //     }
  //   }
  // }

  goToPayment() {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
    this.toastrService.success("Item selecionado com sucesso!");
    this.stepOne = false;
    if (localStorage.getItem('personalData') !== null && localStorage.getItem('address') !== null) {
      let personalData: any = localStorage.getItem('personalData');
      let address: any = localStorage.getItem('address');
      personalData = JSON.parse(personalData);
      address = JSON.parse(address);

      this.formPersonalData.patchValue({
        name: personalData.name,
        cpf: personalData.cpf,
        email: personalData.email,
        phone: personalData.phone,
        date: personalData.date,
      })

      this.formAddress.patchValue({
        cep: address.cep,
        city: address.city,
        uf: address.uf,
      })
    }
    this.stepTwo = true;
    this.stepThree = false;
    this.stepOnePix = true;
    this.stepTwoPix = false;
  }

  backToPayment() {
    this.stepOne = true;
    this.stepTwo = false;
    this.stepThree = false;
  }

  backToHome() {
    this.stepOne = true;
    this.stepTwo = false;
    this.stepThree = false;
    this.stepFour = false;
    this.stepOnePix = true;
    this.stepTwoPix = false;
    this.router.navigate(["/"]);
  }

  validarCPF(cpf: string) {
    cpf = cpf.replace(/[^\d]+/g, "");
    if (cpf == "") return false;
    // Elimina CPFs invalidos conhecidos
    if (
      cpf.length != 11 ||
      cpf == "00000000000" ||
      cpf == "11111111111" ||
      cpf == "22222222222" ||
      cpf == "33333333333" ||
      cpf == "44444444444" ||
      cpf == "55555555555" ||
      cpf == "66666666666" ||
      cpf == "77777777777" ||
      cpf == "88888888888" ||
      cpf == "99999999999"
    )
      return false;
    // Valida 1o digito
    let add = 0;
    for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
    let rev = 11 - (add % 11);
    if (rev == 10 || rev == 11) rev = 0;
    if (rev != parseInt(cpf.charAt(9))) return false;
    // Valida 2o digito
    add = 0;
    for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
    rev = 11 - (add % 11);
    if (rev == 10 || rev == 11) rev = 0;
    if (rev != parseInt(cpf.charAt(10))) return false;
    return true;
  }
}
