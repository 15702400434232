// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  encrypt_key: '5708c8d4-0b2a-4cbd-bea4-99981079020a',
  api: {
     server: 'https://mundo-sorte.codefabrik.dev',
    path: 'https://mundo-sorte.codefabrik.dev',
    socket:'https://mundo-sorte.codefabrik.dev',
    username: 'code',
    password: 'f668d0ee785d',
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
