import { Component } from '@angular/core';

@Component({
  selector: 'app-capitalization-title-info',
  templateUrl: './capitalization-title-info.component.html',
  styleUrls: ['./capitalization-title-info.component.scss']
})
export class CapitalizationTitleInfoComponent {

}
